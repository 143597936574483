import {useAppDispatch, useAppSelector} from "../../store/store";
import {TextMain} from "../../lib/static/text";
import {Row} from "../../lib/markup/markup";
import {useEffect, useState} from "react";
import {firstTouchReportGet, readyToRequest} from "../../store/reports/first_touch";
import {fixWidth} from "../../lib/styles";

export function FirstTouchReportStatus() {
    const {report} = useAppSelector(state => state.firstTouchReport)
    const dispatch = useAppDispatch()
    const [count, setCount] = useState(0)
    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(firstTouchReportGet())
            setCount(count + 1)
        }, 500)

        return () => clearTimeout(timeout)
    }, [count]);

    return <TextMain style={fixWidth(250)}>
        {report.status}
    </TextMain>
}
