import {colTitleBG, Modes, Periods, rowTitleBG} from "../common";
import {borderTable, ph2} from "../../../lib/styles";
import Section from "./Section";
import {DashboardContext} from "../../contexts";
import {useContext} from "react";
import {Flex1} from "../../../lib/markup/markup";


export default function () {
    const titleStyle = {...rowTitleBG, ...ph2, ...borderTable}
    const colTitleStyle = {...colTitleBG, ...ph2, ...borderTable}
    const colStyle = {...ph2, ...borderTable}
    const {year, mode, period} = useContext(DashboardContext)
    if (mode !== Modes.table || period !== Periods.year) return null
    return <Flex1>
        <table>
            <thead>
            <tr>
                <th style={titleStyle}>План/Факт</th>
                <th style={titleStyle}>Месяц</th>
                <th style={titleStyle}>Звонки/заявки</th>
                <th style={titleStyle}>CPL, ₽</th>
                <th style={titleStyle}>Расход, ₽</th>
                <th style={titleStyle}>CR в продажу, %</th>
                <th style={titleStyle}>Успешно реализовано</th>
                <th style={titleStyle}>CPS, ₽</th>
                <th style={titleStyle}>Средний чек, ₽</th>
                <th style={titleStyle}>Доход, ₽</th>
                <th style={titleStyle}>ДРР, %</th>
            </tr>
            </thead>
            <tbody>
            <Section
                prefix={'fact'}
                title={`${year - 1} фактические показатели`}
                colTitleStyle={colTitleStyle}
                colStyle={colStyle}
                year={year - 1}
            />
            <Section
                prefix={'negative'}
                title={`${year} плановые показатели (негативный)`}
                colTitleStyle={colTitleStyle}
                colStyle={colStyle}
                year={year}
            />
            <Section
                prefix={'positive'}
                title={`${year} плановые показатели (позитивный)`}
                colTitleStyle={colTitleStyle}
                colStyle={colStyle}
                year={year}
            />
            <Section
                prefix={'fact'}
                title={`${year} фактические`}
                colTitleStyle={colTitleStyle}
                colStyle={colStyle}
                year={year}
            />
            </tbody>
        </table>
    </Flex1>
}
