import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DELETE, GET, IApiError, PATCH} from "./axios";
import {getObjectIndexById, removeFromArrayById} from "../lib/sugar";
import {amoCrmCheck, amoCrmCreate, amoCrmList, AmoCrmStatus, IAmoCrm, IAmoCrmPatch, initialAmoCrm} from "./amo_crm";
import {ELoadingStatus} from "./common";
import {success} from "../lib/styles";

export enum ENewLeadTaskStatus {
    none='',
    success='success',
    error='error',
    noGCID='no gcid',
    noEmpty='no empty',
    leadNotFound='lead not found'
}

export interface INewLeadTask {
    id: number
    created_at: string
    finished_at: string
    task_status: ENewLeadTaskStatus
    request: object
    data: object
}

export interface ILogs {
    newLeadTasks: INewLeadTask[]
    mainLeadTasks: IMainLeadTask[]
    status: ELoadingStatus
}

export interface IMainLeadTask {
    id: number
    request: string
    created_at: string
    ga_status: EMainLeadStatus
    ym_status: EMainLeadStatus
}

export const initialLogs: ILogs = {
    newLeadTasks: [],
    mainLeadTasks: [],
    status: ELoadingStatus.initial
}

export enum EMainLeadStatus {
    none = '',
    success = 'success',
    requestError = 'request error',
    runtimeError = 'runtime error',
    noGcid = 'no gcid',
    noYcid = 'no ycid',
    notFound = 'lead not found',
    nonTarget = 'non-target',
    noRequest = 'no request',
    unknownStatus = 'unknown status',
    waiting = 'waiting'
}


export const newLeadTaskList = createAsyncThunk(
    'Logs/newLeadTaskList',
    async () => {
        const response = await GET('amocrm/newLeadTasks/')
        return response.data
    }
)

export const mainLeadTaskList = createAsyncThunk(
    'Logs/mainLeadTaskList',
    async () => {
        const response = await GET('amocrm/mainLeadTasks/')
        return response.data
    }
)

export const logSlice = createSlice({
    name: 'Logs',
    initialState: initialLogs,
    reducers: {
    },
    extraReducers: {
        [newLeadTaskList.pending.type]: (state) => {
            state.status = ELoadingStatus.loading
            state.newLeadTasks = []
        },
        [newLeadTaskList.rejected.type]: (state, action: IApiError) => {
            state.status = ELoadingStatus.error
        },
        [newLeadTaskList.fulfilled.type]: (state, action: PayloadAction<INewLeadTask[]>) => {
            state.status = ELoadingStatus.ready
            state.newLeadTasks = action.payload
        },

        [mainLeadTaskList.pending.type]: (state) => {
            state.status = ELoadingStatus.loading
            state.newLeadTasks = []
        },
        [mainLeadTaskList.rejected.type]: (state, action: IApiError) => {
            state.status = ELoadingStatus.error
        },
        [mainLeadTaskList.fulfilled.type]: (state, action: PayloadAction<IMainLeadTask[]>) => {
            state.status = ELoadingStatus.ready
            state.mainLeadTasks = action.payload
        },
    }
})
