import {Col, Flex1, Row} from "../../lib/markup/markup";
import {TextMain} from "../../lib/static/text";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {EUserStatus, userSlice} from "../../store/user";
import {bold, danger, fixWidth, ml1, ml2, p2} from "../../lib/styles";
import {BtnSecondary} from "../../lib/buttons/Btn";
import {getObjectIndexById} from "../../lib/sugar";
import Loading from "../../lib/static/Loading";
import {pageAccessList} from "../../store/page_access";
import PageAccessRow from "./PageAccessRow";
import confirmAlert from "../../lib/modals/confirmAlert";


export default function (): JSX.Element | null {
    const {editingUserId, otherUsers, data:thisUser} = useAppSelector(state => state.user)
    const i = getObjectIndexById(otherUsers, editingUserId)
    const {data, loading} = useAppSelector(state => state.pageAccess)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (data.length) return
        dispatch(pageAccessList())
    }, []);

    const user = i === -1 ? thisUser :otherUsers[i]
    if (!user) return null
    if (!editingUserId) return null
    if (loading) return <Loading/>

    function changeUserStatus() {
        const status =  user?.status === EUserStatus.active ? EUserStatus.banned : EUserStatus.active
        confirmAlert({
            title: statusAction + ' пользователя?',
            yes: () => {
                // @ts-ignore
                dispatch(userSlice.actions.patch({id: user?.id, status}))
            }
        })
    }

    const statusAction = user.status === EUserStatus.active ? 'заблокировать' : 'разблокировать'
    const statusColor = user.status === EUserStatus.banned ? danger : {}
    return <Flex1>
        <Row style={p2}>
            <TextMain style={{...bold, ...statusColor}}>{user.id}</TextMain>
            <TextMain style={{...bold, ...ml2, ...statusColor}}>{user.username}</TextMain>
            <BtnSecondary
                style={ml2}
                title={statusAction}
                onPress={changeUserStatus}
            />
        </Row>
        <Col style={{...fixWidth(200), ...ml1}}>
            {data.map((pageAccess, key) => {
                return <PageAccessRow
                    key={key}
                    {...pageAccess}
                    currentUser={user}
                />
            })}
        </Col>
    </Flex1>
}
