import {Flex1, Row} from "../../lib/markup/markup";
import {fixHeight, mt2, p2} from "../../lib/styles";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {loadData} from "../../store/dashboard/slice";
import {ELoadingStatus} from "../../store/common";
import Loading from "../../lib/static/Loading";
import {
    DashboardContext,
    IDashboardUpdateState, IDetailWindow,
} from '../contexts'
import {Modes, Periods} from "./common";
import {EDate} from "../../lib/sugar";
import {IPipeline} from "../../store/dashboard/consts";
import Menu from "./Menu";
import MonthTable from "./month_table/MonthTable";
import Advertisement from "./advertisement/Advertisement";
import Plan from "./plan/Plan";
import YearsTable from "./years_table/YearsTable";
import YearsCharts from "./years_charts/YearsCharts";
import MonthChart from "./month_table/MonthChart";
import ScrollView from "../../lib/markup/ScrollView";
import Detail from "./Detail";

interface IDashboardState {
    month: number,
    year: number,
    period: Periods,
    mode: Modes,
    pipeline: IPipeline | null,
    detailWindow: IDetailWindow
}

export default function (): JSX.Element | null {
    const dispatch = useAppDispatch()
    const {loading} = useAppSelector(state => state.dashboard)
    const now = new EDate()
    const [state, setState] = useState<IDashboardState>({
        month: now.getMonth(),
        year: now.getFullYear(),
        period: Periods.month,
        mode: Modes.table,
        pipeline: null,
        detailWindow: {visible: false, day: ''}
    })

    function updateState(args: IDashboardUpdateState) {
        setState({...state, ...args})
    }

    function refresh() {
        dispatch(loadData())
    }


    useEffect(() => {
        refresh()

    }, []);

    if (loading === ELoadingStatus.initial) return <Loading/>

    const daysOnMonth = (33 - new Date(state.year, state.month, 33).getDate())
    return <DashboardContext.Provider value={{
        ...state,
        updateState,
        daysOnMonth
    }}>
        <Flex1 style={p2}>
            <Row style={mt2}>
                <Menu/>
            </Row>
            <Row style={{...mt2, ...fixHeight(window.innerHeight - 85)}}>
                <ScrollView>
                    <MonthTable/>
                    <Advertisement/>
                    <Plan/>
                    <MonthChart/>
                    <YearsTable/>
                    <YearsCharts/>
                </ScrollView>
            </Row>
            <Detail/>
        </Flex1>
    </DashboardContext.Provider>
}


