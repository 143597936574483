import {Flex1, Flex2, Row} from "../../lib/markup/markup";
import {border, flex1, flex2, m1, mh2, mt2, mv2, p1, p2, pb2} from "../../lib/styles";
import {ISiteOfflineEvent} from "../../store/observer/consts";
import {H3, TextMain} from "../../lib/static/text";
import {EDate} from "../../lib/sugar";



export default function ({siteOfflineEvents}: {siteOfflineEvents: ISiteOfflineEvent[]}): JSX.Element | null {
    return <Flex2 style={{...p2, ...border, ...m1}}>
        <H3>log:</H3>
        <Row style={mt2}>
            <H3 style={flex2}>url</H3>
            <H3  style={{...mh2, ...flex1}}>недоступен:</H3>
            <H3 style={flex1}>восстановлен:</H3>
        </Row>
        {siteOfflineEvents.map((event, key) => {
            return <Row key={key}>
                <TextMain style={flex2}>{event.website}</TextMain>
                <TextMain style={{...mh2, ...flex1}}>{new EDate(event.created_at).ruDateTime()}</TextMain>
                <TextMain style={flex1}>{event.closed_at ? new EDate(event.closed_at).ruDateTime() : ''}</TextMain>
            </Row>
        })}
    </Flex2>
}


