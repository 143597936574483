import './Loading.css'
import {Flex1} from "../markup/markup";
import {fCenter, jCenter} from "../styles";
export default function () {
    return (
        <Flex1 style={{...jCenter, ...fCenter}}>
            <div className="spinner-container">
                <div className="loading-spinner">
                </div>
            </div>
        </Flex1>
    );
}
