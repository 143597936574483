import {Col, Row} from "../../../lib/markup/markup";
import {useContext, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {advertisementCreate, dashboardSlice} from "../../../store/dashboard/slice";
import {EDate, getObjectIndexById} from "../../../lib/sugar";
import {DateField, NumberField, TextField} from "../../../lib/fields/inputs";
import {
    bgSecondary,
    bold,
    borderTable,
    danger, fEnd, field,
    fixWidth,
    flex1, italic, m2, mb2,
    mt2, p1, pl2,
    primary,
    pt3
} from "../../../lib/styles";
import {H3, TextMain} from "../../../lib/static/text";
import {DashboardContext} from "../../contexts";
import {Modes} from "../common";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Btn, BtnPrimary} from "../../../lib/buttons/Btn";
import confirmAlert from "../../../lib/modals/confirmAlert";
import Select from "../../../lib/fields/Select";
import {IAdvertisementPatch, IPipeline} from "../../../store/dashboard/consts";

export default function () {
    const {month, year, mode, pipeline} = useContext(DashboardContext)
    const {pipelines, advertisements} = useAppSelector(state => state.dashboard)
    const [name, setName] = useState('')
    const [date, setDate] = useState(new EDate());
    const [cost, setCost] = useState(0);
    const vatDefault = 0.2
    const [vat, setVat] = useState(vatDefault);
    const [pipelineId, setPipelineId] = useState(pipeline ? pipeline.id : pipelines[0].id)
    const [error, setError] = useState('')
    const dispatch = useAppDispatch()
    if (mode !== Modes.advertisement) return null

    function create() {
        if (!name) {
            setError('укажите описание')
            return
        }
        if (!cost) {
            setError('укажите стоимость')
            return
        }
        setError('')
        dispatch(advertisementCreate({name, date: date.isoDate(), cost, vat, pipeline: pipelineId}))
        setName('')
        setCost(0)
        setVat(vatDefault)
    }

    function remove(id: number) {
        confirmAlert({
            title: 'удалить?',
            remove: () => dispatch(dashboardSlice.actions.advertisementRemove(id)),
        })
    }

    function change(args: IAdvertisementPatch) {
        dispatch(dashboardSlice.actions.advertisementPatch(args))
    }

    let counter = 0


    const colStyles = {
        data: {...p1, ...fixWidth(60)},
        pipeline: {...p1, ...fixWidth(150)},
        cost: {...fixWidth(120)},
        vat: {...fixWidth(120)},
        name: {...p1, ...flex1},
    }

    const vatItems = [
        {label: 'без НДС', value: 0},
        {label: '10%', value: 0.1},
        {label: '18%', value: 0.18},
        {label: '20%', value: 0.2},
    ]
    const filteredAdvertisements = advertisements.filter((adv) => {
        if (pipeline && pipeline.id !== adv.pipeline) return false
        const date = new EDate(adv.date)
        if (date.getMonth() !== month || date.getFullYear() !== year) return false
        return true
    })
    const commentStyle = {...fixWidth(800), ...mb2, ...italic}
    return <Col style={{...pt3, ...pl2}}>
        <TextMain style={commentStyle}>
            - Расходы на рекламу автоматически подгружаются из яндекс-директ по аккаунтам заданным в интеграциях,
            в 04.00 по Москве. Редактировать их нельзя.
        </TextMain>
        <TextMain style={commentStyle}>
            - Можно добавить вручную, тогда бюджет будет распределен на месяц. Данные расходы редактируемы.
        </TextMain>
        <TextMain style={commentStyle}>
            - Отношение воронка+дата должно быть уникальным (например: не может быть 2х бюджетов на один день для
            Москвы)
        </TextMain>

        <H3 style={mt2}>Добавить расходы на рекламу:</H3>
        <Col style={{...m2, ...fEnd, ...fixWidth(290)}}>
            <DateField
                label={'дата'}
                value={date}
                onChange={setDate}
            />
            <Select
                label={'воронка'}
                items={pipelines.map((pipeline: IPipeline, key: number) => {
                    return {label: pipeline.name, value: pipeline.id}
                })}
                value={String(pipelineId)}
                onChange={value => setPipelineId(Number(value))}
            />
            <NumberField
                label={'стоимость'}
                value={cost}
                onChange={setCost}
            />
            <Select
                label={'НДС'}
                items={vatItems}
                value={String(vat)}
                onChange={value => setVat(Number(value))}
            />
            <TextField
                placeholder={'описание'}
                value={name}
                onChange={setName}
            />
            <BtnPrimary
                width={"field"}
                title={'добавить'}
                onPress={create}
            />
        </Col>
        <Row>
            <TextMain style={danger}>{error}</TextMain>
        </Row>
        <Col style={{...mt2, ...borderTable}}>
            <Row>
                <TextMain style={{...bold, ...bgSecondary, ...colStyles.data}}>Дата</TextMain>
                <TextMain style={{...bold, ...bgSecondary, ...colStyles.pipeline}}>Воронка</TextMain>
                <TextMain style={{...bold, ...bgSecondary, ...colStyles.cost, ...p1}}>Бюджет</TextMain>
                <TextMain style={{...bold, ...bgSecondary, ...colStyles.vat, ...p1}}>НДС</TextMain>
                <TextMain style={{...bold, ...bgSecondary, ...colStyles.name}}>Описание</TextMain>
            </Row>
            {filteredAdvertisements.map((adv, key) => {
                const style = counter % 2 ? {backgroundColor: '#eee'} : {}
                counter++
                const i = getObjectIndexById(pipelines, adv.pipeline)
                const city = i === -1 ? '' : pipelines[i].name
                return <Row style={style} key={key}>
                    <TextMain style={colStyles.data}>{new EDate(adv.date).ruDate(false)}</TextMain>
                    <TextMain style={colStyles.pipeline}>{city}</TextMain>
                    <NumberField
                        style={colStyles.cost}
                        value={adv.cost}
                        onChange={cost => change({cost, id: adv.id})}
                        editable={adv.monthly}
                    />
                    <Select
                        editable={adv.monthly}
                        style={colStyles.vat}
                        value={String(adv.vat)}
                        items={vatItems}
                        onChange={vat => change({vat: +vat, id: adv.id})}
                    />
                    <TextField
                        editable={adv.monthly}
                        style={colStyles.name} value={adv.name}
                        onChange={name => change({name, id: adv.id})}
                    />
                    <Btn
                        disabled={!adv.monthly}
                        width={'auto'}
                        icon={faTrash}
                        onPress={() => remove(adv.id)}
                        style={primary}
                    />
                </Row>
            })}
        </Col>
    </Col>
}
