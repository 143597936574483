import {EDate, round} from "../../lib/sugar";
import {IAdvertisement, ILead, IPipeline, IPlan, IStatus} from "../../store/dashboard/consts";
import {IFact, initialFact, ISummary, IWeekSummary} from "../contexts";
import {Percent, Rub} from "../../lib/static/text";

export const Months = [
    {label: 'январь', value: 0},
    {label: 'февраль', value: 1},
    {label: 'март', value: 2},
    {label: 'апрель', value: 3},
    {label: 'май', value: 4},
    {label: 'июнь', value: 5},
    {label: 'июль', value: 6},
    {label: 'август', value: 7},
    {label: 'сентябрь', value: 8},
    {label: 'октябрь', value: 9},
    {label: 'ноябрь', value: 10},
    {label: 'декабрь', value: 11},
]

export enum Modes {
    table,
    chart,
    advertisement,
    plan
}


export enum Periods {
    month,
    year
}

export interface IDayOfWeek {
    title: string
    leadsCount: number
    leadSuccessCount: number
    totalPrice: number
    isoDate:string
    dayNumber: number
}

export function getCRValue(leadSuccessCount: number, leadTotal: number): number {
    if (!leadTotal) return 0
    let num = leadSuccessCount * 100 / leadTotal
    num = round(num, 2)
    return num
}

export function getCPLValue(advertisingBudget: number, leadsTotal: number): number {
    if (!leadsTotal) return 0
    return round(advertisingBudget / leadsTotal, 2)
}

export function getCPSValue(advertisingBudget: number, leadSuccessCount: number) {
    if (!leadSuccessCount) return 0
    return round(advertisingBudget / leadSuccessCount, 2)
}

export function getAverageTicketValue(totalPrice: number, leadSuccessCount: number) {
    if (!leadSuccessCount) return 0
    return round(totalPrice / leadSuccessCount, 2)
}

export function getDPPValue(advertisingBudget: number, income: number) {
    income = Number(income)
    if (!income) return 0
    advertisingBudget = Number(advertisingBudget)
    return round(advertisingBudget / income, 2)
}

export function getCR(leadSuccessCount: number, leadTotal: number): JSX.Element {
    return <Percent>{getCRValue(leadSuccessCount, leadTotal)}</Percent>
}


export function getCPL(advertisingBudget: number, leadsTotal: number): JSX.Element {
    return <Rub>{getCPLValue(advertisingBudget, leadsTotal)}</Rub>
}

export function getCPS(advertisingBudget: number, leadSuccessCount: number): JSX.Element {
    return <Rub>{getCPLValue(advertisingBudget, leadSuccessCount)}</Rub>
}

export function getAverageTicket(totalPrice: number, leadSuccessCount: number): JSX.Element {
    return <Rub>{getAverageTicketValue(totalPrice, leadSuccessCount)}</Rub>
}

export function getDPP(advertisingBudget: number, income: number): JSX.Element {
    return <Percent>{getDPPValue(advertisingBudget, income)}</Percent>
}

export interface IWeek {
    title: string
    days: IDayOfWeek[]
}

export const rowTitleBG = {backgroundColor: '#D9EAD3'}
export const colTitleBG = {backgroundColor: '#FFF2CC'}

interface IFactWeek extends IFact {
    advertisingBudget: number,
    advertisingBudgetWithoutVat: number
}

export function calculateDaysOfWeeks(year: number,
                                     month: number,
                                     leads: ILead[],
                                     pipeline: IPipeline | null,
                                     advertisements: IAdvertisement[]) {

    const weekdays: IFactWeek[] = []
    for (let i = 0; i < 7; i++) weekdays.push({...initialFact, advertisingBudget: 0})
    for (const lead of leads) {
        if (!suitablePipeline(lead, pipeline)) continue
        let i = new EDate(lead.created_at).getDay()
        i = i ? i - 1 : 6
        if (suitableCreatedAt(lead, month, year)) weekdays[i].leadsCount++
        if (suitableClosedAt(lead, month, year)) {
            weekdays[i].leadSuccessCount++
            weekdays[i].income += getIncome(lead)
        }
    }
    let monthly = 0
    for (const adv of advertisements) {
        if (!suitableAdv(adv, month, year, pipeline)) continue
        const i = new EDate(adv.date).getDay()
        if (adv.monthly) monthly += adv.cost
        else weekdays[i].advertisingBudget += adv.cost
    }
    monthly = round(monthly/7, 2)
    for (let n = 0; n < weekdays.length; n++) {
        weekdays[n].advertisingBudget += monthly
    }
    return weekdays
}


export function calculateFactTotal(
    month: number,
    year: number,
    pipeline: IPipeline | null,
    leads: ILead[],
): IFact {
    const total: IFact = {...initialFact}

    for (const lead of leads) {
        if (!suitablePipeline(lead, pipeline)) continue
        if (suitableCreatedAt(lead, month, year)) total.leadsCount++
        if (suitableClosedAt(lead, month, year)) {
            total.leadSuccessCount++
            total.income += getIncome(lead)
        }
    }
    return total
}

export function suitableClosedAt(lead: ILead, month: number, year: number) {
    if (!lead.closed_at) return false
    const leadDate = new EDate(lead.closed_at)
    return leadDate.getMonth() === month && leadDate.getFullYear() === year
}

export function suitableCreatedAt(lead: ILead, month: number, year: number) {
    const leadDate = new EDate(lead.created_at)
    return leadDate.getMonth() === month && leadDate.getFullYear() === year
}
export function suitablePipeline(lead: ILead, pipeline: IPipeline| null) {
    if (!pipeline) return true
    return pipeline.id === lead.pipeline
}

export interface IAdvDayBudget {
    full: number
    withoutVat: number
}

export function calculateAdv(advertisements: IAdvertisement[],
                             year: number,
                             month: number,
                             pipeline: IPipeline | null,
                             daysOnMonth: number
) {
    let advTotal = 0
    let monthly = 0
    const advDayBudget: IAdvDayBudget[] = []
    for (let n = 0; n < daysOnMonth; n++) {
        advDayBudget.push({full: 0, withoutVat: 0})
    }
    for (let adv of advertisements) {
        if (!suitableAdv(adv, month, year, pipeline)) continue
        if (adv.monthly) monthly += adv.cost
        else {
            const n = new EDate(adv.date).getDate() - 1
            advDayBudget[n].full += adv.cost
            advDayBudget[n].withoutVat += adv.cost * (1 - adv.vat)
        }
        advTotal += adv.cost
    }
    monthly = round(monthly / daysOnMonth, 2)
    for (let n = 0; n < advDayBudget.length; n++) {
        advDayBudget[n].full += monthly
    }
    return {advDayBudget, advTotal}
}

export function suitableAdv(adv: IAdvertisement, month: number, year: number, pipeline: IPipeline | null) {
    const date = new EDate(adv.date)
    if (date.getMonth() !== month || date.getFullYear() !== year) return false
    if (pipeline && adv.pipeline !== pipeline.id) return false
    return true
}

export function calculateWeeks(props: {
    leads: ILead[],
    month: number,
    year: number,
    pipeline: IPipeline | null,
    advertisements: IAdvertisement[]
}) {
    const weeksDates = new EDate(props.year, props.month, 1).weeksOnMonth
    const weeks: IWeek[] = []
    for (let week of weeksDates) {
        const days: IDayOfWeek[] = []
        for (const day of week) {
            days.push({
                title: `${day.ruDate(false)} ${day.weekDay(true)}`,
                isoDate: day.isoDate(),
                totalPrice: 0,
                leadSuccessCount: 0,
                leadsCount: 0,
                dayNumber: day.getDate()-1
            })
        }
        weeks.push({
            // @ts-ignore
            title: `${week[0].ruDate(false)}-${week.at(-1).ruDate(false)}`,
            days
        })
    }

    function getDayIndex(days: IDayOfWeek[], isoDate: string) {
        if (!days) return
        for (let n=0; n<days.length; n++) {
            if (days[n].isoDate === isoDate) return n
        }
    }

    for (let lead of props.leads) {
        if (!suitablePipeline(lead, props.pipeline)) continue
        if (suitableCreatedAt(lead, props.month, props.year)) {
            const date = new EDate(lead.created_at)
            const weekIndex = date.weekOnMonthIndex
            const isoDate = date.isoDate()
            const dayIndex = getDayIndex(weeks[weekIndex]?.days, isoDate)
            if (dayIndex !== undefined) weeks[weekIndex].days[dayIndex].leadsCount++
        }
        if (suitableClosedAt(lead, props.month, props.year)) {
            const date = new EDate(lead.closed_at)
            const weekIndex = date.weekOnMonthIndex
            const isoDate = date.isoDate()
            const dayIndex = getDayIndex(weeks[weekIndex]?.days, isoDate)
            if (dayIndex !== undefined) {
                weeks[weekIndex].days[dayIndex].leadSuccessCount++
                weeks[weekIndex].days[dayIndex].totalPrice += getIncome(lead)
                // if (weeks[weekIndex].days[dayIndex].title.includes('08.01 ')) {
                //     console.log(lead)
                // }
            }

        }
    }
    return weeks
}

export function calculatePlanTotal(plans: IPlan[], month: number, year: number, pipeline: IPipeline | null) {
    const totalPlans = {
        leads_count_negative: 0,
        leads_count_positive: 0,
        success_leads_negative: 0,
        success_leads_positive: 0,
        advertisement_budget: 0,
        income_negative: 0,
        income_positive: 0,
    }
    for (let plan of plans) {
        if (plan.month !== month || plan.year !== year) continue
        if (pipeline && plan.pipeline !== pipeline.id) continue
        totalPlans.leads_count_negative += plan.leads_count_negative
        totalPlans.leads_count_positive += plan.leads_count_positive
        totalPlans.success_leads_negative += plan.success_leads_negative
        totalPlans.success_leads_positive += plan.success_leads_positive
        totalPlans.advertisement_budget += plan.advertisement_budget
        totalPlans.income_negative += plan.income_negative
        totalPlans.income_positive += plan.income_positive
    }
    return totalPlans
}


export function calculateWeekSummary(year: number,
                                     month: number,
                                     leads: ILead[],
                                     pipeline: IPipeline | null,
                                     plans: IPlan[]) {
    const initialSummary: ISummary = {
        leadsCount: 0,
        leadsSuccessCount: 0,
        income: 0
    }

    function extendSummary(index: number) {
        while (summary.length < index + 1) {
            summary.push({
                factYearBefore: {...initialSummary},
                factThisYear: {...initialSummary},
                planThisYear: {...initialSummary}
            })
        }
    }

    const summary: IWeekSummary[] = []
    for (const lead of leads) {
        if (!suitablePipeline(lead, pipeline)) continue
        if (suitableCreatedAt(lead, month, year)) {
            const i = new EDate(lead.created_at).weekOnMonthIndex
            extendSummary(i)
            summary[i].factThisYear.leadsCount++
        } else if (suitableCreatedAt(lead, month, year-1)) {
            const i = new EDate(lead.created_at).weekOnMonthIndex
            extendSummary(i)
            summary[i].factYearBefore.leadsCount++
        }
        if (suitableClosedAt(lead, month, year)) {
            const i = new EDate(lead.created_at).weekOnMonthIndex
            extendSummary(i)
            summary[i].factThisYear.leadsSuccessCount++
            summary[i].factThisYear.income += getIncome(lead)
        }       else if (suitableClosedAt(lead, month, year-1)) {
            const i = new EDate(lead.created_at).weekOnMonthIndex
            extendSummary(i)
            summary[i].factYearBefore.leadsSuccessCount++
            summary[i].factYearBefore.income += getIncome(lead)
        }
    }

    for (const plan of plans) {
        if (pipeline && pipeline.id !== plan.pipeline) continue
        if (plan.month !== month || plan.year !== year) continue
        summary[0].planThisYear.leadsCount += plan.leads_count_negative
        summary[0].planThisYear.leadsSuccessCount += plan.success_leads_negative
        summary[0].planThisYear.income += plan.income_negative
    }
    for (let key of ['leadsCount', 'leadsSuccessCount', 'income']) {
        // @ts-ignore
        summary[0].planThisYear[key] = round(summary[0].planThisYear[key] / summary.length, 0)
    }

    for (let n = 1; n < summary.length; n++) {
        for (let field of ['factYearBefore', 'factThisYear']) {
            for (let key of ['leadsCount', 'leadsSuccessCount', 'income']) {
                // @ts-ignore
                summary[n][field][key] += summary[n - 1][field][key]
            }
        }
        for (let key of ['leadsCount', 'leadsSuccessCount', 'income']) {
            // @ts-ignore
            summary[n].planThisYear[key] = summary[0].planThisYear[key] * (n + 1)
        }
    }
    return summary
}

export function getIncome(lead: ILead) {
    if (lead.price_manual_input) {
        console.log(lead.price_manual_input, '................')
        return lead.price_manual_input
    }
    if (lead.price === 1) return 0
    return lead.price
}
