import {createContext} from "react";
import {IWeek, Modes, Periods} from "./dashboard/common";
import {IAdvertisement, IPipeline} from "../store/dashboard/consts";

export interface IPlan {
    leads_count_negative: number
    leads_count_positive: number
    success_leads_negative: number
    success_leads_positive: number
    income_negative: number,
    income_positive: number,
    advertisement_budget: number
}

export interface IFact {
    leadsCount: number
    income: number
    leadSuccessCount: number
}

export const initialFact = {
    leadsCount: 0,
    income: 0,
    leadSuccessCount: 0,
    advertisingBudget: 0,
    advertisingBudgetWithoutVat: 0
}

export interface ITotal {
    plan: IPlan
    fact: IFact
}

export const initialTotal: ITotal = {
    plan: {
        leads_count_negative: 0,
        leads_count_positive: 0,
        success_leads_negative: 0,
        success_leads_positive: 0,
        income_negative: 0,
        income_positive: 0,
        advertisement_budget: 0
    },
    fact: {
        leadsCount: 0,
        income: 0,
        leadSuccessCount: 0,
    }
}

export interface ISummary {
    leadsCount: number
    leadsSuccessCount: number
    income: number
}

export interface IWeekSummary {
    factYearBefore: ISummary
    factThisYear: ISummary
    planThisYear: ISummary
}
export interface IDashboardUpdateState {
    month?: number, year?: number, period?: Periods, mode?: Modes, pipeline?: IPipeline | null, detailWindow?: IDetailWindow
}

export interface IDetailWindow {
    visible: boolean
    day: string
}

interface IDashboardContext {
    pipeline: IPipeline | null
    mode: Modes
    period: Periods
    month: number
    year: number
    updateState: (args: IDashboardUpdateState) => void
    daysOnMonth: number
    detailWindow: IDetailWindow
}


export const DashboardContext = createContext<IDashboardContext>({
    pipeline: null,
    updateState: () => {},
    mode: Modes.table,
    period: Periods.month,
    month: -1,
    year: -1,
    daysOnMonth: 0,
    detailWindow: {visible: false, day: ''}
})
