import {Col, Flex1, Row} from "../../lib/markup/markup";
import {DateField} from "../../lib/fields/inputs";
import {m1, ml2, ml5, mt2, p2, success} from "../../lib/styles";
import {EDate} from "../../lib/sugar";
import {useEffect, useState} from "react";
import {BtnPrimary} from "../../lib/buttons/Btn";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {ELoadingStatus} from "../../store/common";
import {
    firstTouchReportGet,
    firstTouchReportSlice,
    readyToRequest
} from "../../store/reports/first_touch";
import {H3, TextMain} from "../../lib/static/text";
import Loading from "../../lib/static/Loading";
import confirmAlert from "../../lib/modals/confirmAlert";
import {FirstTouchReportStatus} from "./FirstTouchReportStatus";


export default function () {
    const [start, setStart] = useState(new EDate().change({days: -30}));
    const [stop, setStop] = useState(new EDate());
    const dispatch = useAppDispatch()
    const {report} = useAppSelector(state => state.firstTouchReport)

    function reset() {
        confirmAlert({
            title: 'сбросить?',
            yes: () => dispatch(firstTouchReportSlice.actions.reset())
        })
    }

    function make() {
        dispatch(firstTouchReportSlice.actions.make({start: start.isoDate(), stop: stop.isoDate()}))
    }
    function getFileName() {
        if (!report.file) return
        const p = report.file.split('/')
        return p[p.length-1]
    }

    function downloadReport() {
        window.open( report.file)
    }


        return <Flex1 style={p2}>
            <TextMain>выгрузка из гугл занимает много времени, следует подождать</TextMain>
            <Row style={m1}>
                <Col>
                    <DateField
                        value={start}
                        label={'с'}
                        onChange={setStart}
                    />
                </Col>
                <Col style={ml2}>
                    <DateField
                        value={stop}
                        label={'до'}
                        onChange={setStop}
                    />
                </Col>
                <BtnPrimary
                    visible={readyToRequest(report.status)}
                    style={ml2}
                    onPress={make}
                    title={'сформировать'}
                />
                <BtnPrimary style={ml2} onPress={reset} title={'сброс'}/>
            </Row>
            <Row style={p2}>
                <Col>
                    <FirstTouchReportStatus/>
                </Col>
                <Col style={ml5} onPress={downloadReport}>
                    <TextMain style={success}>{getFileName()}</TextMain>
                </Col>
            </Row>
        </Flex1>
}
