import {Flex1} from "../../lib/markup/markup";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {userCreate, usersAll} from "../../store/user";
import Loading from "../../lib/static/Loading";
import UserRow from "./UserRow";
import Creator from "../../lib/fields/Creator";
import {danger, m1, m2, mv1, mv2} from "../../lib/styles";
import {TextMain} from "../../lib/static/text";


export default function (): JSX.Element | null {
    const dispatch = useAppDispatch()
    const {loading, otherUsers, data:user, userCreateError} = useAppSelector(state => state.user)
    const [email, setEmail] = useState('')

    useEffect(() => {
        dispatch(usersAll())
    }, [])

    function create() {
        dispatch(userCreate(email))
    }

    if (loading) return <Loading/>

    return <Flex1>
        <Creator
            style={m1}
            placeholder={'e-mail'}
            value={email}
            onChange={setEmail}
            onPress={create}
        />
        <TextMain style={{...m1, ...danger}}>{userCreateError}</TextMain>
        {user && <UserRow {...user}/>}
        {otherUsers.map((user, key) => {
            return <UserRow key={key} {...user}/>
        })}
    </Flex1>
}


