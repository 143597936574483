import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DELETE, GET, IApiError, PATCH, POST} from "./axios";
import {getObjectIndexById, removeFromArrayById} from "../lib/sugar";
import {danger, success} from "../lib/styles";
import {noop} from "@reduxjs/toolkit/dist/listenerMiddleware/utils";
import {IAmoCrm} from "./amo_crm";


export interface IGoogleAnalytics {
    id: number
    name: string
    api_key: string
    online: boolean
    main_lead_day_limit:number
    cache_last_updated_at: string
    main_lead_day_count: number
}

export enum GoogleStatus {
    initial,
    loading,
    error,
    ready
}

export enum EKeyStatus {
    initial,
    ready,
    loading,
    error
}

export interface IAlGoogleList {
    data: IGoogleAnalytics[]
    status: GoogleStatus
    keyStatus: EKeyStatus
    keyMessage: string
    keyMessageStyle: object | null
    checking: boolean
}

export const initialIGoogleAnalytics: IAlGoogleList = {
    data: [],
    status: GoogleStatus.initial,
    keyStatus: EKeyStatus.initial,
    keyMessage: '',
    keyMessageStyle: null,
    checking: false
}

export const googleAnalyticsList = createAsyncThunk(
    'Google/list',
    async () => {
        const response = await GET<IGoogleAnalytics[]>(GooglesURL)
        return response.data
    }
)

export const removeApiKey = createAsyncThunk(
    'Google/removeApiKey',
    async (id: number) => {
        PATCH(`${GooglesURL}${id}/remove_api_key/`, {})
        return id
    }
)

const WRONG_JSON = 'wrongJSON'

export const uploadApiKey = createAsyncThunk(
    'Google/uploadApiKey',
    async ({id, api_key}: { id: number, api_key: File }) => {
        if (!api_key.name.endsWith('.json')) throw WRONG_JSON
        const args = new FormData()
        args.append('api_key', api_key)
        await PATCH(`${GooglesURL}${id}/`, args)
        return id
    }
)

export const googleAnalyticsCheck = createAsyncThunk(
    'Google/check_connection',
    async (id: number) => {
        const response = await GET<IAmoCrm>(GooglesURL + id + '/check_connection/')
        return response.data
    }
)

export const GooglesURL = 'google/GoogleAnalytics/'

export const googleAnalyticsCreate = createAsyncThunk(
    'Google/create',
    async (args: { name: string }) => {
        const response = await POST<IGoogleAnalytics>(GooglesURL, args)
        return response.data
    }
)

export interface IGoogleAnalyticsPath {
    id: number
    name?: string
}

export const googleAnalyticsSlice = createSlice({
    name: 'IGoogleAnalytics',
    initialState: initialIGoogleAnalytics,
    reducers: {
        patch: (state, action: PayloadAction<IGoogleAnalyticsPath>) => {
            const id = action.payload.id
            const i = getObjectIndexById(state.data, action.payload.id)
            PATCH(`${GooglesURL}${id}/`, action.payload)
            state.data[i] = {...state.data[i], ...action.payload}
        },
        remove: (state, action: PayloadAction<number>) => {
            DELETE(GooglesURL + action.payload + '/')
            state.data = removeFromArrayById(state.data, action.payload)
        }
    },
    extraReducers: {
        [googleAnalyticsList.pending.type]: (state) => {
            state.keyMessage = ''
            state.status = GoogleStatus.loading
        },
        [googleAnalyticsList.rejected.type]: (state, action: IApiError) => {
            state.status = GoogleStatus.error
        },
        [googleAnalyticsList.fulfilled.type]: (state, action: PayloadAction<IGoogleAnalytics[]>) => {
            state.data = action.payload
            state.status = GoogleStatus.ready
        },

        [googleAnalyticsCreate.pending.type]: (state) => {
            state.keyMessage = ''
            state.status = GoogleStatus.loading
        },
        [googleAnalyticsCreate.rejected.type]: (state, action: IApiError) => {
            state.status = GoogleStatus.error
        },
        [googleAnalyticsCreate.fulfilled.type]: (state, action: PayloadAction<IGoogleAnalytics>) => {
            state.data.push(action.payload)
            state.status = GoogleStatus.ready
        },

        [removeApiKey.pending.type]: (state) => {
            state.keyMessage = ''
            state.keyStatus = EKeyStatus.loading
        },
        [removeApiKey.rejected.type]: (state, action: IApiError) => {
            state.keyStatus = EKeyStatus.error
            state.keyMessageStyle = danger
            state.keyMessage = action.error.message
        },
        [removeApiKey.fulfilled.type]: (state, action: PayloadAction<number>) => {
            state.keyStatus = EKeyStatus.ready
            state.keyMessageStyle = success
            state.keyMessage = 'ключ удален'
            const index = getObjectIndexById(state.data, action.payload)
            state.data[index].api_key = ''
        },

        [uploadApiKey.pending.type]: (state) => {
            state.keyStatus = EKeyStatus.loading
            state.keyMessage = ''
        },
        [uploadApiKey.rejected.type]: (state, action: IApiError) => {
            state.keyStatus = EKeyStatus.error
            state.keyMessageStyle = danger
            if (action.error.message === WRONG_JSON) state.keyMessage = 'ключ должен быть в формате json'
            else state.keyMessage = action.error.message
        },
        [uploadApiKey.fulfilled.type]: (state, action: PayloadAction<number>) => {
            state.keyStatus = EKeyStatus.ready
            state.keyMessageStyle = success
            state.keyMessage = 'ключ загружен'
            const index = getObjectIndexById(state.data, action.payload)
            state.data[index].api_key = 'key.json'
        },


        [googleAnalyticsCheck.pending.type]: (state) => {
            state.checking = true
            state.keyMessage = ''
        },
        [googleAnalyticsCheck.rejected.type]: (state, action: IApiError) => {
            state.checking = false
        },
        [googleAnalyticsCheck.fulfilled.type]: (state, action: PayloadAction<IGoogleAnalytics>) => {
            state.checking = false
            const index = getObjectIndexById(state.data, action.payload.id)
            state.data[index] = action.payload
        },
    }
})



