import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";
import {BtnPrimary, BtnSecondary} from "./Btn";

interface IBtnToggle {
    visible?: boolean
    value: boolean
    onPress: any
    title?: string
    icon?: IconDefinition | null
    iconSize?: SizeProp
    style?: object
}

export default function BtnToggle (props: IBtnToggle): JSX.Element | null {
    if (!props.visible) return null

    if (props.value) return <BtnPrimary {...props}/>
    return <BtnSecondary {...props}/>
}

BtnToggle.defaultProps = {
    visible: true,
    value:false,
    onPress: null,
    icon: null,
    iconSize: '1x',
    title:'',
    style: {}
}
