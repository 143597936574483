import {amoCrmCheck, amoCrmSlice, IAmoCrm, IAmoCrmPatch} from "../../../store/amo_crm";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {IntegrationTitle, IntegrationWrapper} from "../../common";
import {TextField} from "../../../lib/fields/inputs";

export default function (props: IAmoCrm): JSX.Element | null {
    const dispatch = useAppDispatch()

    const fields = [
        'name', 'client_secret', 'client_id', 'code', 'subdomain', 'redirect_url'
    ]

    function patch(args: IAmoCrmPatch) {
        dispatch(amoCrmSlice.actions.patch(args))
    }
    function checkConnection() {
        dispatch(amoCrmCheck(props.id))
    }

    function remove() {
        dispatch(amoCrmSlice.actions.remove(props.id))
    }

    return <IntegrationWrapper online={props.online}>
        <IntegrationTitle title={'amoCRM'} online={props.online} remove={remove}/>
        {fields.map((field, key) => {
            return <TextField
                label={field}
                key={key}
                placeholder={field}
                // @ts-ignore
                value={props[field]}
                onChange={(value) => patch({id: props.id, [field]: value})}
                onBlur={checkConnection}
            />
        })}
    </IntegrationWrapper>
}

