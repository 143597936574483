import {field, fixHeight, fixWidth} from "../styles";

export interface IItem {
    label: string
    value: string | number
}

export interface ISelect {
    visible?: boolean
    items: IItem[]
    value: string
    label?: string
    onChange: (arg0: string) => void
    style?: object
    editable?: boolean
}

const sSelect = {
    ...field,
    ...fixWidth(field.width + 8),
    ...fixHeight(field.height + 8)
}

export default function ({
                             visible = true,
                             items = [],
                             value = '',
                             label = '',
                             onChange = () => {
                             },
                             editable = true,
                             style = {}
                         }: ISelect): JSX.Element | null {
    if (!visible) return null
    return <label>{label ? label + ': ' : ''}
        <select
            disabled={!editable}
            style={{...sSelect, ...style}}
            onChange={e => onChange(e.target.value)}
            value={value}
        >
            {items.map((item, key) => {
                return <option
                    key={key}
                    value={item.value}
                >
                    {item.label}
                </option>
            })}
        </select>
    </label>

}
