import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SizeProp, IconPrefix} from "@fortawesome/fontawesome-svg-core";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faCoffee} from "@fortawesome/free-solid-svg-icons";

export interface IFA {
    visible?: boolean
    icon?: IconDefinition
    size: SizeProp
    color: string
}

export default function ({
                             visible = true,
                             icon = faCoffee,
                             size = 'lg',
                             color = '#000'
                         }: IFA): JSX.Element | null {
    if (!visible) return null
    return <FontAwesomeIcon icon={icon} size={size}  style={{color}}/>
}
