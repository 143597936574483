import {useContext, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store/store";
import {AuthStatus, requestPassword, userLogin} from "../store/user";
import {fCenter, mv1} from "../lib/styles";
import {Col, Flex1, Flex5} from "../lib/markup/markup";
import {TextField} from "../lib/fields/inputs";
import {BtnPrimary} from "../lib/buttons/Btn";
import BtnTimer from "../lib/buttons/BtnTimer";
import {TextMain} from "../lib/static/text";
import Loading from "../lib/static/Loading";
import {storage} from "../store/storage";
import {AppContext} from "../contexts";


export default function () {
    const {authStatus, emailMessage, codeMessage, canPasswordRequestInSeconds}
        = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()
    const [username, setUsername] = useState('')
    const [code, setCode] = useState('')
    const usernameCache = 'usernameCache'

    async function requestCode() {
        await storage.set(usernameCache, username, false)
        dispatch(requestPassword(username))
    }

    function login() {
        dispatch(userLogin({username, password: code}))
    }

    useEffect(() => {
        storage.get(usernameCache, false).then(username => {
            if (username) setUsername(username)
        })
        if(authStatus === AuthStatus.authorised) {
            window.location.reload()
        }
    }, [authStatus])

    return <Flex1>
        <Flex1/>
        <Flex5 style={fCenter}>
            <TextField
                style={mv1}
                placeholder={'e-mail'}
                onChange={setUsername}
                value={username}
                onKey={{Enter: requestCode}}
            />
            <TextMain style={{...emailMessage?.style, ...mv1}} visible={!!emailMessage}>{emailMessage?.text}</TextMain>
            <Col visible={authStatus === AuthStatus.codeRequesting}>
                <TextMain>запрос кода авторизации...</TextMain>
                <Loading/>
            </Col>
            <TextField
                style={mv1}
                visible={authStatus === AuthStatus.codeRequested}
                value={code}
                placeholder={'введите код здесь'}
                onChange={setCode}
                onKey={{Enter: requestCode}}
                keyBoardType={'numeric'}
            />
            <TextMain
                style={{...codeMessage?.style, ...mv1}}
                visible={!!codeMessage}
            >
                {codeMessage?.text}
            </TextMain>
            <BtnPrimary
                visible={authStatus === AuthStatus.unauthorized}
                style={mv1}
                title={'выслать код'}
                onPress={requestCode}
                width={'field'}
            />
            <BtnTimer
                startSeconds={canPasswordRequestInSeconds}
                visible={authStatus === AuthStatus.codeRequested}
                style={mv1}
                onPress={requestCode}
                endMessage={'выслать повторно'}
                width={'field'}
            />
            <BtnPrimary
                style={mv1}
                visible={authStatus === AuthStatus.codeRequested}
                title={'войти'}
                onPress={login}
                width={'field'}
            />
        </Flex5>
    </Flex1>
}
