import {
    borderTable,
    flex1,
    minWidth,
    noBorder,
    pointer, pr0,
    pr3,
    tableCell,
    tableRow,
    tableStyle,
    tableTitle
} from "../styles";
import {EDate} from "../sugar";
import {DateField, NumberField, TextField} from "../fields/inputs";
import {Percent, Rub, TextMain, Number, H3} from "../static/text";
import Select from "../fields/Select";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {Btn} from "../buttons/Btn";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import confirmAlert from "../modals/confirmAlert";
import {dashboardSlice} from "../../store/dashboard/slice";

export interface IItem {
    label: string
    value: string
}

export interface IColumn {
    label: string
    type: 'select' | 'textField' | 'numberField' | 'dateField' | 'text' | 'remove' | 'rub' | '%' | 'number'
    onChange?: (args: { id: string, value: any }) => void
    onRowClick?: (rowId: string) => void
    onTitleClick?: (titleId: string) => void
    style?: object
    fieldName?: string
    items?: IItem[]
}

export interface IRow {
    id?: string
    style?: object
    cells: any[]
}

export interface ITable {
    onTableClick?: () => void
    columns: IColumn[]
    data: IRow[]
    headerStyle?: object
    remove?: (id: string) => void
    title?: string
}

export default function (props: ITable) {
    const fieldStyle = {minWidth, ...noBorder, width: '100%'}
    return <div>
        {props.title ? <H3>{props.title}</H3>: null}
        <table onClick={props.onTableClick} style={tableStyle}>
        <thead>
        <tr>
            {props.columns.map((column, key) => {
                const cursor = !column.onTitleClick ? {} : pointer
                return <th
                    key={key}
                    style={{...tableTitle, ...cursor, ...props.headerStyle}}
                    // @ts-ignore
                    onClick={_ => column?.onTitleClick(column?.fieldName)}
                >
                    {column.label}
                </th>
            })}
        </tr>
        </thead>
        <tbody>
        {props.data.map((row, key) => {
            const cursor = !props.columns[key]?.onRowClick ? {} : pointer
            return <tr
                key={key}
                // @ts-ignore
                onClick={_ => {
                    if(props.columns[key].onRowClick) { // @ts-ignore
                        props.columns[key].onRowClick(row.id)
                    }
                }}
                style={{...tableRow, ...cursor, ...row?.style}}
            >
                {
                    row.cells.map((cell, key) => {
                        switch (props.columns[key].type) {
                            case 'textField':
                                return <td key={key} style={tableCell}>
                                    <TextField
                                        style={fieldStyle}
                                        value={cell}
                                        // @ts-ignore
                                        onChange={value => {
                                            const args = {id: row.id}
                                            // @ts-ignore
                                            args[props.columns[key].fieldName] = value
                                            // @ts-ignore
                                            props.columns[key]?.onChange(args)
                                        }}
                                    />
                                </td>
                            case 'numberField':
                                return <td key={key} style={tableCell}>
                                    <NumberField
                                        style={fieldStyle}
                                        value={cell}
                                        // @ts-ignore
                                        onChange={value => {
                                            const args = {id: row.id}
                                            // @ts-ignore
                                            args[props.columns[key].fieldName] = +value
                                            // @ts-ignore
                                            props.columns[key]?.onChange(args)
                                        }}
                                    />
                                </td>
                            case 'dateField':
                                return <td key={key} style={tableCell}>
                                    <DateField
                                        style={fieldStyle}
                                        value={cell}
                                        // @ts-ignore
                                        onChange={value => {
                                            const args = {id: row.id}
                                            // @ts-ignore
                                            args[props.columns[key].fieldName] = +value
                                            // @ts-ignore
                                            props.columns[key]?.onChange(args)
                                        }}
                                    />
                                </td>
                            case 'select':
                                return <td key={key} style={tableCell}>
                                    <Select
                                        style={fieldStyle}
                                        // @ts-ignore
                                        items={props.columns[key].items}
                                        value={cell}
                                        // @ts-ignore
                                        onChange={value => {
                                            const args = {id: row.id}
                                            // @ts-ignore
                                            args[props.columns[key].fieldName] = +value
                                            // @ts-ignore
                                            props.columns[key]?.onChange(args)
                                        }}
                                    />
                                </td>
                            case 'rub':
                                return <td key={key} style={tableCell}>
                                    <Rub>{cell}</Rub>
                                </td>
                            case '%':
                                return <td key={key} style={tableCell}>
                                    <Percent>{cell}</Percent>
                                </td>
                            case 'number':
                                return <td key={key} style={tableCell}>
                                    <Number>{cell}</Number>
                                </td>
                            default:
                                return <td key={key} style={tableCell}>
                                    <TextMain>{cell}</TextMain>
                                </td>
                        }
                    })
                }
                {!!props.remove ?
                    <td key={key} style={{...tableCell, ...pr0}}>
                        <Btn
                            icon={faTrash}
                            onPress={() => {
                                if (!props.remove) return

                                confirmAlert({
                                    title: 'удалить?',
                                    // @ts-ignore
                                    remove: () => props.remove(row.id)
                                })
                            }}/>
                    </td>
                    :
                    null
                }
            </tr>
        })}
        </tbody>
    </table>
    </div>
}
