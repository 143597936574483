export const textShadow = {
    textShadowColor: '#fff',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 10
}

export const primary = {
    color: '#2f2f2f'
}

export const primaryLight = {
    color: '#494949'
}

export const secondary = {
    color: "#a9a9a9"
}

export const secondaryLight = {
    color: "#efefef"
}

export const danger = {
    color: "#980404"
}

export const dangerLight = {
    color: "#ff0c0c"
}

export const success = {
    color: "#1e7502"
}

export const bgPrimary = {
    backgroundColor: primary.color
}

export const bgPrimaryLight = {
    backgroundColor: primaryLight.color
}

export const bgSecondary = {
    backgroundColor: secondary.color
}

export const bgSecondaryLight = {
    backgroundColor: secondaryLight.color
}

export const bgDanger = {
    backgroundColor: danger.color
}

export const bgDangerLight = {
    backgroundColor: dangerLight.color
}

export const bgWhite = {
    backgroundColor: '#fff'
}

export const bgTransparent = {
    backgroundColor: 'transparent'
}

export const shadow = {
    shadowColor: '#333',
    shadowOpacity: 0.9,
    shadowRadius: 20,
    elevation: 20,
    shadowOffset: {
        width: 0,
        height: 11,
    },

}

export const bold = {fontWeight: 'bold'}
export const italic = {fontStyle: 'italic'}

export const red = {color: 'red'}
export const darkred = {color: 'darkred'}
export const green = {color: 'green'}
export const white = {color: 'white'}
export const black = {color: 'black'}
export const blue = {color: 'blue'}
export const transparent = {color: 'transparent'}
export const background = {
    header: {backgroundColor: '#fff'},
    body: {backgroundColor: '#ddd'}
}



export const fontMain = {
    fontSize: 16,
    color: '#000'
}

export const fontTitle = {
    fontSize: 20,
    color: primary.color,
    ...textShadow,
    fontWeight: 'bold'
}

export const fontComment = {
    fontSize: 12,
    color: '#222',
    fontStyle: 'italic'
}

export const fontPlaceholder = {
    fontSize: 12,
    color: '#666'
}

export const absolute = {position: 'absolute'}

export const m0 = {margin: 0}
export const m1 = {margin: 5}
export const m2 = {margin: 10}
export const m3 = {margin: 15}
export const m4 = {margin: 20}
export const m5 = {margin: 25}

export const ml0 = {marginLeft: m0.margin}
export const ml1 = {marginLeft: m1.margin}
export const ml2 = {marginLeft: m2.margin}
export const ml3 = {marginLeft: m3.margin}
export const ml4 = {marginLeft: m4.margin}
export const ml5 = {marginLeft: m5.margin}

export const mr0 = {marginRight: m0.margin}
export const mr1 = {marginRight: m1.margin}
export const mr2 = {marginRight: m2.margin}
export const mr3 = {marginRight: m3.margin}
export const mr4 = {marginRight: m4.margin}
export const mr5 = {marginRight: m5.margin}

export const mb0 = {marginBottom: m0.margin}
export const mb1 = {marginBottom: m1.margin}
export const mb2 = {marginBottom: m2.margin}
export const mb3 = {marginBottom: m3.margin}
export const mb4 = {marginBottom: m4.margin}
export const mb5 = {marginBottom: m5.margin}

export const mt0 = {marginTop: m0.margin}
export const mt1 = {marginTop: m1.margin}
export const mt2 = {marginTop: m2.margin}
export const mt3 = {marginTop: m3.margin}
export const mt4 = {marginTop: m4.margin}
export const mt5 = {marginTop: m5.margin}

export const mv0 = {marginTop: m0.margin, marginBottom: m0.margin}
export const mv1 = {marginTop: m1.margin, marginBottom: m1.margin}
export const mv2 = {marginTop: m2.margin, marginBottom: m2.margin}
export const mv3 = {marginTop: m3.margin, marginBottom: m3.margin}
export const mv4 = {marginTop: m4.margin, marginBottom: m4.margin}
export const mv5 = {marginTop: m5.margin, marginBottom: m5.margin}

export const mh0 = {marginLeft: m0.margin, marginRight: m0.margin}
export const mh1 = {marginLeft: m1.margin, marginRight: m1.margin}
export const mh2 = {marginLeft: m2.margin, marginRight: m2.margin}
export const mh3 = {marginLeft: m3.margin, marginRight: m3.margin}
export const mh4 = {marginLeft: m4.margin, marginRight: m4.margin}
export const mh5 = {marginLeft: m5.margin, marginRight: m5.margin}

export const p0 = {padding: 0}
export const p1 = {padding: 3}
export const p2 = {padding: 6}
export const p3 = {padding: 9}

export const pt0 = {paddingTop: p0.padding}
export const pt1 = {paddingTop: p1.padding}
export const pt2 = {paddingTop: p2.padding}
export const pt3 = {paddingTop: p3.padding}

export const pb0 = {paddingBottom: p0.padding}
export const pb1 = {paddingBottom: p1.padding}
export const pb2 = {paddingBottom: p2.padding}
export const pb3 = {paddingBottom: p3.padding}

export const pr0 = {paddingRight: p0.padding}
export const pr1 = {paddingRight: p1.padding}
export const pr2 = {paddingRight: p2.padding}
export const pr3 = {paddingRight: p3.padding}

export const pl0 = {paddingLeft: p0.padding}
export const pl1 = {paddingLeft: p1.padding}
export const pl2 = {paddingLeft: p2.padding}
export const pl3 = {paddingLeft: p3.padding}

export const ph0 = {paddingLeft: p0.padding, paddingRight: p0.padding}
export const ph1 = {paddingLeft: p1.padding, paddingRight: p1.padding}
export const ph2 = {paddingLeft: p2.padding, paddingRight: p2.padding}
export const ph3 = {paddingLeft: p3.padding, paddingRight: p3.padding}

export const pv0 = {paddingTop: p0.padding, paddingBottom: p0.padding}
export const pv1 = {paddingTop: p1.padding, paddingBottom: p1.padding}
export const pv2 = {paddingTop: p2.padding, paddingBottom: p2.padding}
export const pv3 = {paddingTop: p3.padding, paddingBottom: p3.padding}

export const mainWidth = 200
export const minWidth = 40
export const maxWidth = 200

export const heightSmall = {
    height: fontComment.fontSize + p1.padding,
    maxHeight: fontComment.fontSize + p1.padding,
    minHeight: fontComment.fontSize + p1.padding
}

export const heightNormal = {
    height: fontMain.fontSize + p1.padding,
    maxHeight: fontMain.fontSize + p1.padding,
    minHeight: fontMain.fontSize + p1.padding
}

export const heightBig = {
    height: fontTitle.fontSize + p3.padding,
    maxHeight: fontTitle.fontSize + p3.padding,
    minHeight: fontTitle.fontSize + p3.padding
}

export const fRow = {flexDirection: 'row'}
export const fWrap = {flexWrap: 'wrap'}
export const noWrap = {flexWrap: 'nowrap'}
export const fStart = {alignItems: 'flex-start', textAlign: 'left'}
export const fEnd = {alignItems: 'flex-end', textAlign: 'right'}
export const fCenter = {alignItems: 'center', textAlign: 'center'}

export const jStart = {justifyContent: 'flex-start'}
export const jEnd = {justifyContent: 'flex-end'}
export const jCenter = {justifyContent: 'center'}
export const jAround = {justifyContent: 'space-around'}
export const jBetween = {justifyContent: 'space-between'}

export const flex1 = {display: 'flex', flex: 1}
export const flex2 = {display: 'flex', flex: 2}
export const flex3 = {display: 'flex', flex: 3}
export const flex4 = {display: 'flex', flex: 4}
export const flex5 = {display: 'flex', flex: 5}
export const flex6 = {display: 'flex', flex: 6}

export const stickRight = {
    marginRight: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0
}

export const stickLeft = {
    marginLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
}

export const stickBottom = {
    marginBottom: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
}

export const stickTop = {
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
}

export const stick = {
    ...stickLeft,
    ...stickRight
}

export const borderRadius = {borderRadius: 5}
export const borderColor = {borderColor: '#777'}
export const borderWidth = {borderWidth: 1}
export const borderStyle = {borderStyle: 'solid'}
export const border = {...borderWidth, ...borderColor, ...borderRadius, ...borderStyle}

export const noBorder = {borderColor: 'transparent'}
export const noRadius = {borderRadius: 0}
export const borderTable = {...borderWidth, ...borderColor, ...noRadius, ...borderStyle}
export const tableStyle = {...borderTable}
export const tableTitle = {...borderTable, ...bold, ...p1}
export const tableRow = {...borderTable}
export const tableCell = {...borderTable, ...pr3}

export const fieldWidth = {
    minWidth: mainWidth,
    width: mainWidth,
    maxWidth: mainWidth
}

export const pointer = {
    cursor: 'pointer'
}
export const field = {
    backgroundColor: '#fff',
    ...fieldWidth,
    ...heightNormal,
    ...border,
    ...p1,
}

export const flexField = {
    backgroundColor: '#fff',
    ...flex1,
    ...heightNormal,
    ...border,
    ...ph1,
}

export const btnWidth = {
    minWidth,
    maxWidth
}

export const btnStyle = {
    ...heightNormal,
    ...btnWidth,
    zIndex: 3,
    elevation: 3,
}

export const esc = {title: 'отмена'}

export const emptyTime = '--:--'

export enum sides {
    one = 'вопрос',
    two = 'ответ'
}


export function fixHeight(height: number) {
    return {minHeight: height, height, maxHeight: height}
}

export function fixWidth(width: number) {
    return {minWidth: width, width, maxWidth: width}
}

