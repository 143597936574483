import './markup.css';

export interface IMarkup {
    children?: any
    visible?: boolean
    style?: object
    onPress?: any
}

export interface IFlex extends IMarkup {
    flex: number
}

export function Flex({
                         children = null,
                         visible = true,
                         style = {},
                         onPress = null,
                         flex = 1,
                     }: IFlex): JSX.Element | null {
    if (!visible) return null
    return <div
        style={{display: 'flex', flexDirection: 'column', flex: flex, cursor: onPress ? 'pointer' : 'auto', ...style}}
        onClick={onPress}
    >
        {children}
    </div>
}

export function Flex1(props: IMarkup): JSX.Element | null {
    return <Flex flex={1} {...props}/>
}
export function Flex2(props: IMarkup): JSX.Element | null {
    return <Flex flex={2} {...props}/>
}
export function Flex3(props: IMarkup): JSX.Element | null {
    return <Flex flex={3} {...props}/>
}
export function Flex4(props: IMarkup): JSX.Element | null {
    return <Flex flex={4} {...props}/>
}
export function Flex5(props: IMarkup): JSX.Element | null {
    return <Flex flex={5} {...props}/>
}
export function Flex6(props: IMarkup): JSX.Element | null {
    return <Flex flex={6} {...props}/>
}
export function Flex7(props: IMarkup): JSX.Element | null {
    return <Flex flex={7} {...props}/>
}
export function Flex8(props: IMarkup): JSX.Element | null {
    return <Flex flex={10} {...props}/>
}


export function Row({
                        children = null,
                        visible = true,
                        style = {},
                        onPress = null,
                    }: IMarkup): JSX.Element | null {
    if (!visible) return null
    return <div
        style={{display: 'flex', width: '100%', flexDirection: 'row', cursor: onPress ? 'pointer' : 'auto', ...style}}
        onClick={onPress}
    >
        {children}
    </div>
}

export function Col({
                        children = null,
                        visible = true,
                        style = {},
                        onPress = null,
                    }: IMarkup): JSX.Element | null {
    if (!visible) return null
    return <div
        style={{flex: 0, display: 'flex', flexDirection: 'column', cursor: onPress ? 'pointer' : 'auto', ...style}}
        onClick={onPress}
    >
        {children}
    </div>
}
