import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GET, IApiError} from "./axios";


export interface IPageAccess {
    id: number
    name: string
    users: number[]

}

export interface IPageAccessList {
    data: IPageAccess[]
    loading: boolean
}
export const initialPageAccess: IPageAccessList = {
    data: [],
    loading: false
}

const pageAccessUrl = 'main/PageAccess/'

export const pageAccessList = createAsyncThunk(
    'pageAccess/list',
    async () => {
        const response = await GET<IPageAccess[]>(pageAccessUrl)
        return response.data
    }
)


export const pageAccessSlice = createSlice({
    name: 'user',
    initialState: initialPageAccess,
    reducers: {

    },
    extraReducers: {
        [pageAccessList.pending.type]: (state) => {
            state.loading = true
        },
        [pageAccessList.rejected.type]: (state, action: IApiError) => {
            state.loading = false
        },
        [pageAccessList.fulfilled.type]: (state, action: PayloadAction<IPageAccess[]>) => {
            state.data = action.payload
            state.loading = false
        },
    }
})



