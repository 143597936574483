import {createContext} from "react";


interface IAppContext {
    navigation: {
        navigate: (arg0: string) => void
        goBack: () => void
    }
}


const InitialAppContext: IAppContext = {
    // @ts-ignore
    navigation: null,
}
export const AppContext = createContext(InitialAppContext)
