import {
    colTitleBG,
    getAverageTicketValue,
    getCPLValue,
    getCPSValue,
    getCRValue,
    getDPPValue,
    rowTitleBG
} from "../../common";
import {borderTable, fixWidth, ph2} from "../../../../lib/styles";
import {DashboardContext, IFact, IPlan} from "../../../contexts";
import {useContext} from "react";
import {EDate, round} from "../../../../lib/sugar";
import {useAppSelector} from "../../../../store/store";
import {Number, Percent, Rub} from "../../../../lib/static/text";

interface IStatusTable {
    advTotal: number
    plan: IPlan
    fact: IFact
}

export default function ({advTotal, plan, fact}: IStatusTable): JSX.Element | null {
    const {month, daysOnMonth} = useContext(DashboardContext)

    const titleStyle = {...rowTitleBG, ...ph2, ...borderTable}
    const colTitleStyle = {...colTitleBG, ...ph2, ...borderTable}
    const colTitleStyle2 = {backgroundColor: '#D9D2E9', ...ph2, ...borderTable}
    const colStyle = {...ph2, ...borderTable}

    const planCpl = getCPLValue(plan.advertisement_budget, plan.leads_count_negative)
    const planCr = getCRValue(plan.success_leads_negative, plan.leads_count_negative)
    const planCps = getCPSValue(plan.advertisement_budget, plan.success_leads_negative)
    const planAverageTicket = getAverageTicketValue(plan.income_negative, plan.success_leads_negative)
    const planDpp = getDPPValue(plan.advertisement_budget, plan.income_negative)

    const planLeadsForDate = forDate(plan.leads_count_negative, 0)
    const planCplForDate = forDate(planCpl)
    const planAdvertisingBudgetForDate = forDate(plan.advertisement_budget)
    const planCrForDate = forDate(planCr)
    const planLeadSuccessCountForDate = forDate(plan.success_leads_negative)
    const planCpsForDate = forDate(planCps)
    const planAverageTicketForDate = forDate(planAverageTicket)
    const planPriceForDate = forDate(plan.income_negative)
    const planDppForDate = forDate(planDpp)

    const factCpl = getCPLValue(advTotal, fact.leadsCount)
    const factCr = getCRValue(fact.leadSuccessCount, fact.leadsCount)
    const factCps = getCPSValue(advTotal, fact.leadSuccessCount)
    const factAverageTicket = getAverageTicketValue(fact.income, fact.leadSuccessCount)
    const factDpp = getDPPValue(advTotal, fact.income)

    const percentLeadsForDate = percentFor(fact.leadsCount, planLeadsForDate)
    const percentCplForDate = percentFor(factCpl, planCpl)
    const percentAdvertisingBudgetForDate = percentFor(advTotal, planAdvertisingBudgetForDate)
    const percentCrForDate = percentFor(factCr, planCrForDate)
    const percentLeadSuccessCountForDate = percentFor(fact.leadSuccessCount, planLeadSuccessCountForDate)
    const percentCpsForDate = percentFor(factCps, planCpsForDate)
    const percentAverageTicketForDate = percentFor(factAverageTicket, planAverageTicketForDate)
    const percentPriceForDate = percentFor(fact.income, planPriceForDate)
    const percentDppForDate = percentFor(factDpp, planDppForDate)

    const percentLeadsForMonth = percentFor(fact.leadsCount, plan.leads_count_negative)
    const percentCplForMonth = percentFor(factCpl, planCpl)
    const percentAdvertisingBudgetForMonth = percentFor(advTotal, plan.advertisement_budget)
    const percentCrForMonth = percentFor(factCr, planCr)
    const percentLeadSuccessCountForMonth = percentFor(fact.leadSuccessCount, plan.success_leads_negative)
    const percentCpsForMonth = percentFor(factCps, planCps)
    const percentAverageTicketForMonth = percentFor(factAverageTicket, planAverageTicket)
    const percentPriceForMonth = percentFor(fact.income, plan.income_negative)
    const percentDppForMonth = percentFor(factDpp, planDpp)

    function percentFor(value: number, max: number, decimal = 2) {
        if (!max) return 0
        return round(value * 100 / max, decimal)
    }

    function forDate(value: number, decimal = 2) {
        const now = new EDate()
        const days = now.getMonth() === month ? now.getDate() : daysOnMonth
        return round(value * days / daysOnMonth, decimal)
    }
    return <table>
        <thead>
        <tr>
            <th style={{...titleStyle, ...fixWidth(200)}}>Статус строки</th>
            <th style={titleStyle}>Звонки/заявки</th>
            <th style={{...titleStyle, ...fixWidth(120)}}>CPL, ₽</th>
            <th style={{...titleStyle, ...fixWidth(120)}}>Расход, ₽</th>
            <th style={titleStyle}>CR в продажу, %</th>
            <th style={titleStyle}>Успешно реализовано</th>
            <th style={{...titleStyle, ...fixWidth(120)}}>CPS, ₽</th>
            <th style={{...titleStyle, ...fixWidth(150)}}>Средний чек, ₽</th>
            <th style={{...titleStyle, ...fixWidth(150)}}>Доход, ₽</th>
            <th style={{...titleStyle, ...fixWidth(60)}}>ДРР, %</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td style={colTitleStyle}>ИТОГО (план) - месяц:</td>
            <td style={colStyle}><Number>{plan.leads_count_negative}</Number></td>
            <td style={colStyle}><Rub>{planCpl}</Rub></td>
            <td style={colStyle}><Rub>{plan.advertisement_budget}</Rub></td>
            <td style={colStyle}><Percent>{planCr}</Percent></td>
            <td style={colStyle}><Number>{plan.success_leads_negative}</Number></td>
            <td style={colStyle}><Rub>{planCps}</Rub></td>
            <td style={colStyle}><Rub>{planAverageTicket}</Rub></td>
            <td style={colStyle}><Rub>{plan.income_negative}</Rub></td>
            <td style={colStyle}><Percent>{planDpp}</Percent></td>
        </tr>
        <tr>
            <td style={colTitleStyle}>ИТОГО (план) - на дату:</td>
            <td style={colStyle}><Number>{planLeadsForDate}</Number></td>
            <td style={colStyle}><Rub>{planCplForDate}</Rub></td>
            <td style={colStyle}><Rub>{planAdvertisingBudgetForDate}</Rub></td>
            <td style={colStyle}><Percent>{planCrForDate}</Percent></td>
            <td style={colStyle}><Number>{planLeadSuccessCountForDate}</Number></td>
            <td style={colStyle}><Rub>{planCpsForDate}</Rub></td>
            <td style={colStyle}><Rub>{planAverageTicketForDate}</Rub></td>
            <td style={colStyle}><Rub>{planPriceForDate}</Rub></td>
            <td style={colStyle}><Percent>{planDppForDate}</Percent></td>
        </tr>
        <tr>
            <td style={colTitleStyle}>ИТОГО (факт):</td>
            <td style={colStyle}><Number>{fact.leadsCount}</Number></td>
            <td style={colStyle}><Rub>{factCpl}</Rub></td>
            <td style={colStyle}><Rub>{advTotal}</Rub></td>
            <td style={colStyle}><Percent>{factCr}</Percent></td>
            <td style={colStyle}><Number>{fact.leadSuccessCount}</Number></td>
            <td style={colStyle}><Rub>{factCps}</Rub></td>
            <td style={colStyle}><Rub>{factAverageTicket}</Rub></td>
            <td style={colStyle}><Rub>{fact.income}</Rub></td>
            <td style={colStyle}><Percent>{factDpp}</Percent></td>
        </tr>
        <tr>
            <td style={colTitleStyle2}>% выполнения - месяц:</td>
            <td style={colStyle}><Percent>{percentLeadsForMonth}</Percent></td>
            <td style={colStyle}><Percent>{percentCplForMonth}</Percent></td>
            <td style={colStyle}><Percent>{percentAdvertisingBudgetForMonth}</Percent></td>
            <td style={colStyle}><Percent>{percentCrForMonth}</Percent></td>
            <td style={colStyle}><Percent>{percentLeadSuccessCountForMonth}</Percent></td>
            <td style={colStyle}><Percent>{percentCpsForMonth}</Percent></td>
            <td style={colStyle}><Percent>{percentAverageTicketForMonth}</Percent></td>
            <td style={colStyle}><Percent>{percentPriceForMonth}</Percent></td>
            <td style={colStyle}><Percent>{percentDppForMonth}</Percent></td>
        </tr>
        <tr>
            <td style={colTitleStyle2}>% выполнения - на дату:</td>
            <td style={colStyle}><Percent>{percentLeadsForDate}</Percent></td>
            <td style={colStyle}><Percent>{percentCplForDate}</Percent></td>
            <td style={colStyle}><Percent>{percentAdvertisingBudgetForDate}</Percent></td>
            <td style={colStyle}><Percent>{percentCrForDate}</Percent></td>
            <td style={colStyle}><Percent>{percentLeadSuccessCountForDate}</Percent></td>
            <td style={colStyle}><Percent>{percentCpsForDate}</Percent></td>
            <td style={colStyle}><Percent>{percentAverageTicketForDate}</Percent></td>
            <td style={colStyle}><Percent>{percentPriceForDate}</Percent></td>
            <td style={colStyle}><Percent>{percentDppForDate}</Percent></td>
        </tr>
        </tbody>
    </table>
}

