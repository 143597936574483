import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Btn} from "./Btn";
import ConfirmAlert from "../modals/confirmAlert";

interface IRemove {
    onPress: () => void
    confirmMessage?: string
    confirm: boolean
}
export default function (props: IRemove) {
    function remove() {
        if (props.confirm) {
            ConfirmAlert({
                title: props.confirmMessage ? props.confirmMessage : 'Удалить?',
                remove: props.onPress
            })
        } else props.onPress()

    }
    return <Btn icon={faTrash} onPress={remove}/>
}
