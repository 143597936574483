import {calculateWeekSummary, Periods, rowTitleBG} from "../common";
import {DashboardContext} from "../../contexts";
import {useContext} from "react";
import {useAppSelector} from "../../../store/store";
import Table, {IColumn, IRow} from "../../../lib/table/Table";
import {Flex1} from "../../../lib/markup/markup";

export default function () {
    const {year, month, pipeline, mode, period} = useContext(DashboardContext)
    if (period !== Periods.month) return null
    const {plans, leads} = useAppSelector(state => state.dashboard)
    const summary = calculateWeekSummary(year, month, leads, pipeline, plans)
    const tables = [
        {field: 'leadsCount', label: 'звонки/заявки', type: 'number'},
        {field: 'leadsSuccessCount', label: 'успешно реализовано', type: 'number'},
        {field: 'income', label: 'доход', type: 'rub'},
    ]

    const weekNumbers = ['I', 'II', 'III', 'IV', 'V']
    return <Flex1>
        {tables.map(({field, label, type}, key) => {
            const columns: IColumn[] = [
                {label: 'Неделя', type: 'text'},
                // @ts-ignore
                {label: `Факт ${year-1}`, type},
                // @ts-ignore
                {label: `План ${year}`, type},
                // @ts-ignore
                {label: `Факт ${year}`, type},
            ]
            const data: IRow[] = []
            for (let n=0; n<summary.length; n++) {
                data.push({
                    cells: [
                        weekNumbers[n] + ' неделя',
                        // @ts-ignore
                        summary[n].factYearBefore[field],
                        // @ts-ignore
                        summary[n].planThisYear[field],
                        // @ts-ignore
                        summary[n].factThisYear[field],
                    ]
                })
            }
            return <Table
                key={key}
                headerStyle={rowTitleBG}
                title={`Сводная суммарная по периодам: ${label}`}
                columns={columns}
                data={data}
            />
        })}
    </Flex1>
}
