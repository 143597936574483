import {IYandexApp, IYandexAppPath, yandexApp, yandexAppCheck} from "../../../store/yandex";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {IntegrationTitle, IntegrationWrapper} from "../../common";
import {TextField} from "../../../lib/fields/inputs";
import {googleAnalyticsCheck} from "../../../store/google";
import {Col, Row} from "../../../lib/markup/markup";
import {TextMain} from "../../../lib/static/text";
import Toggle from "../../../lib/Toggle";
import {getObjectIndexById} from "../../../lib/sugar";
import {fEnd, jEnd} from "../../../lib/styles";

export default function (props: IYandexApp): JSX.Element | null {
    const dispatch = useAppDispatch()
    const fields = ['name', 'access_token', 'counter_id', 'client_id']
    const {access} = useAppSelector(state => state.yandexApp)

    function patch(args: IYandexAppPath) {
        dispatch(yandexApp.actions.patch(args))
    }

    function checkConnection() {
        dispatch(yandexAppCheck(props.id))
    }

    function remove() {
        dispatch(yandexApp.actions.remove(props.id))
    }

    function changeAccess(access_id: string) {
        dispatch(yandexApp.actions.changeAccess({app_id: props.id, access_id}))
    }

    return <IntegrationWrapper online={props.online}>
        <IntegrationTitle title={'yandex'} online={props.online} remove={remove}/>
        {fields.map((field, key) => {
            return <TextField
                label={field}
                key={key}
                placeholder={field}
                // @ts-ignore
                value={props[field]}
                onChange={(value) => patch({id: props.id, [field]: value})}
                onBlur={checkConnection}
            />
        })}


            <TextMain>сервисы:</TextMain>
            <Row style={fEnd}>
                {access.map((acc, key)  => {
                    return <Toggle
                        key={key}
                        label={acc.service}
                        value={props.access.includes(acc.id)}
                        onPress={() => changeAccess(acc.id)}
                    />
                })}
            </Row>

    </IntegrationWrapper>
}

