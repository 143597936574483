import {useAppSelector} from "../../../store/store";
import {useContext} from "react";
import {DashboardContext} from "../../contexts";
import {EDate} from "../../../lib/sugar";
import {getAverageTicket, getCPL, getCPS, getCR, getDPP} from "../common";
import {Number, Rub} from "../../../lib/static/text";

interface IMonth {
    prefix: string
    colStyle: object
    month: {label: string, value: number} | null
    year: number
}

export default function ({prefix, colStyle, month, year}: IMonth) {
    const {leads, advertisements, plans} = useAppSelector(state => state.dashboard)
    const {pipeline} = useContext(DashboardContext)
    let leadsCount = 0
    let leadsSuccess = 0
    let income = 0
    let advBudget = 0
    if (prefix !== 'fact') {
        for (const plan of plans) {
            if ( year !== plan.year) continue
            if (month && plan.month !== month.value) continue
            if (pipeline && plan.pipeline !== pipeline.id) continue
            if (prefix === 'positive') {
                leadsCount += plan.leads_count_positive
                income += plan.income_positive
                leadsSuccess += plan.success_leads_positive
            } else {
                leadsCount += plan.leads_count_negative
                income += plan.income_negative
                leadsSuccess += plan.success_leads_negative
            }
            advBudget += plan.advertisement_budget
        }
    } else {
        for (const lead of leads) {
            if (pipeline && lead.pipeline !== pipeline.id) continue
            const createdAt = new EDate(lead.created_at)
            if (year !== createdAt.getFullYear()) continue
            if (month && month.value !== createdAt.getMonth()) continue
            leadsCount++
            if (!!lead.closed_at) {
                leadsSuccess++
                if (lead.price !==1) income += lead.price
            }
        }
        for (let adv of advertisements) {
            if (pipeline && adv.pipeline !== pipeline.id) continue
            const date = new EDate(adv.date)
            if (year !== date.getFullYear()) continue
            if (month && month.value !== date.getMonth()) continue
            advBudget += adv.cost
        }
    }
    return <>
        <td style={colStyle}>{month ? month.label : 'итого'}</td>
        <td style={colStyle}><Number>{leadsCount}</Number></td>
        <td style={colStyle}>{getCPL(advBudget, leadsCount)}</td>
        <td style={colStyle}><Rub>{advBudget}</Rub></td>
        <td style={colStyle}>{getCR(leadsSuccess, leadsCount)}</td>
        <td style={colStyle}><Number>{leadsSuccess}</Number></td>
        <td style={colStyle}>{getCPS(advBudget, leadsSuccess)}</td>
        <td style={colStyle}>{getAverageTicket(income, leadsSuccess)}</td>
        <td style={colStyle}><Rub>{income}</Rub></td>
        <td style={colStyle}>{getDPP(advBudget, income)}</td>
    </>
}
