import {Col} from "../../../lib/markup/markup";
import {mt2} from "../../../lib/styles";
import {H3} from "../../../lib/static/text";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";


export interface IChart {
    title: string
    yearBefore: string
    planPositive: string
    planNegative: string
    fact: string
    data: any[]
}

export default function (props: IChart) {
    return <Col style={mt2}>
        <H3>{props.title}</H3>
        <LineChart
            width={window.innerWidth - 30}
            height={400}
            // @ts-ignore
            data={props.data}
            margin={{top: 15, right: 20, left: 10, bottom: 5}}
        >
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>
            <CartesianGrid stroke="#f5f5f5"/>
            <Legend/>
            <Line type="monotone" dataKey={props.yearBefore} stroke={'blue'}/>
            <Line type="monotone" dataKey={props.planPositive} stroke={'green'}/>
            <Line type="monotone" dataKey={props.planNegative} stroke={'red'}/>
            <Line type="monotone" dataKey={props.fact} stroke={'orange'}/>
        </LineChart>
    </Col>
}
