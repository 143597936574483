import Loading from "../../lib/static/Loading";
import {Flex1, Row} from "../../lib/markup/markup";
import {border, danger, fixWidth, m1, mh1, mh2, mv1, p1, p2, pb2, primary} from "../../lib/styles";
import Creator from "../../lib/fields/Creator";
import {INotificationEmail, ISiteOfflineEvent} from "../../store/observer/consts";
import {H3, TextMain} from "../../lib/static/text";
import {EDate, isEmailCorrect} from "../../lib/sugar";
import siteOfflineEvents from "./SiteOfflineEvents";
import {Btn} from "../../lib/buttons/Btn";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch} from "../../store/store";
import confirmAlert from "../../lib/modals/confirmAlert";
import {observerObjectCreate, observerSlice} from "../../store/observer/slice";
import {useState} from "react";



export default function ({notificationEmails}: {notificationEmails: INotificationEmail[]}): JSX.Element | null {
    const dispatch = useAppDispatch()
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)

    function remove(id: number) {
        confirmAlert({
            title: 'удалить email?',
            yes: () => dispatch(observerSlice.actions.remove({objects: 'notificationEmails', id}))
        })
    }

    function create() {
        if (!isEmailCorrect(email)) {
            setError(true)
            return
        }
        setError(false)
        dispatch(observerObjectCreate({objects: "notificationEmails", args: {email}}))
    }

    return <Flex1 style={{...p2, ...border, ...m1}}>
        <H3 style={pb2}>отправлять уведомления на:</H3>
        <Creator
            style={mv1}
            placeholder={'email'}
            onChange={setEmail}
            value={email}
            onPress={create}
        />
        <TextMain visible={error} style={danger}>неверный email</TextMain>
        {notificationEmails.map((obj, key) => {
            return <Row key={key} style={mv1}>
                <TextMain style={fixWidth(200)}>{obj.email}</TextMain>
                <Btn
                    style={primary}
                    width={'auto'}
                    icon={faTrash}
                    onPress={() => remove(obj.id)}
                />
            </Row>
        })}
    </Flex1>
}


