import {Col, Row} from "../../lib/markup/markup";
import AmoCrm from "./amo_crm/AmoCrm";
import Google from "./google/Google";
import Yandex from "./yandex/Yandex";
import AddIntegration from "./AddIntegration";
import {m3} from "../../lib/styles";


export default function () {
    return <Col>
        <Row style={m3}>
            <AddIntegration/>
        </Row>
        <Row>
            <Col>
                <AmoCrm/>
            </Col>
            <Col>
                <Google/>
            </Col>
            <Col>
                <Yandex/>
            </Col>
        </Row>
    </Col>
}
