import {Row} from "../../lib/markup/markup";
import BtnToggle from "../../lib/buttons/BtnToggle";
import {fixWidth, ml2, mr2, stick, stickLeft, stickRight} from "../../lib/styles";
import {BtnSecondary} from "../../lib/buttons/Btn";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import Select from "../../lib/fields/Select";
import {Modes, Months, Periods} from "./common";
import {NumberField} from "../../lib/fields/inputs";
import {useContext} from "react";
import {DashboardContext} from "../contexts";
import {useAppSelector} from "../../store/store";

export default function () {
    const {
        pipeline,
        updateState,
        month,
        year,
        mode,
        period,
    } = useContext(DashboardContext);
    const {pipelines} = useAppSelector(state => state.dashboard)

    function validateSetYear(year: number) {
        if (String(year).length < 4) return
        updateState({year})
    }

    function changeMonth(num: number) {
        const m = month + num
        if (m > 11) {
            updateState({year:year + 1, month: 0})
        } else if (m < 0) {
            updateState({year:year - 1, month: 11})
        } else updateState({month:m})
    }

    return <Row>
        <Row style={fixWidth(230)}>
            <BtnToggle
                title={'Таблица'}
                onPress={() => updateState({mode:Modes.table})}
                value={mode === Modes.table}
                style={stickRight}
            />
            <BtnToggle
                title={'График'}
                onPress={() => updateState({mode:Modes.chart})}
                value={mode === Modes.chart}
                style={stick}
            />
            <BtnToggle
                title={'Реклама'}
                onPress={() => updateState({mode:Modes.advertisement})}
                value={mode === Modes.advertisement}
                style={stick}
            />
            <BtnToggle
                title={'План'}
                onPress={() => updateState({mode: Modes.plan})}
                value={mode === Modes.plan}
                style={stickLeft}
            />

        </Row>
        <Row>
            <BtnToggle
                title={'Месяц'}
                onPress={() => updateState({period:Periods.month})}
                value={period === Periods.month}
                style={{...stickRight, ...ml2}}
            />
            <BtnToggle
                title={'Год'}
                onPress={() => updateState({period:Periods.year})}
                value={period === Periods.year}
                style={{...stickLeft, ...mr2}}
            />

            <BtnToggle
                title={'Все'}
                onPress={() => updateState({pipeline:null})}
                value={!pipeline}
                style={stickRight}
            />
            {pipelines.map((p, key) => {
                let style: object = key === pipelines.length - 1 ? stickLeft : stick
                return <BtnToggle
                    key={key}
                    title={p.name}
                    onPress={() => updateState({pipeline:p})}
                    value={pipeline?.id === p.id}
                    style={style}
                />
            })}

            <BtnSecondary
                visible={period === Periods.month && mode !== Modes.plan}
                icon={faChevronLeft}
                style={{...stickRight, ...ml2}}
                onPress={() => changeMonth(-1)}
            />
            <Select
                visible={period === Periods.month  && mode !== Modes.plan}
                style={{...stick, ...fixWidth(90)}}
                value={String(month)}
                items={Months}
                onChange={month => {
                    updateState({month:+month})
                }}
            />
            <BtnSecondary
                visible={period === Periods.month && mode !== Modes.plan}
                icon={faChevronRight}
                style={{...stickLeft}}
                onPress={() => changeMonth(1)}
            />

            <BtnSecondary
                icon={faChevronLeft}
                style={{...stickRight, ...ml2}}
                onPress={() => updateState({year:year - 1})}
            />
            <NumberField
                style={{...stick, ...fixWidth(50)}}
                value={year}
                onChange={validateSetYear}
            />
            <BtnSecondary
                icon={faChevronRight}
                style={{...stickLeft}}
                onPress={() => updateState({year:year + 1})}
            />
        </Row>
    </Row>
}
