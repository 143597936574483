import {Col, Flex1} from "../../lib/markup/markup";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {EDate} from "../../lib/sugar";
import {useEffect, useState} from "react";
import {DateField, NumberField} from "../../lib/fields/inputs";
import {fEnd, fixWidth, m2, mb2, mv2} from "../../lib/styles";
import {ELoadingStatus} from "../../store/common";
import Loading from "../../lib/static/Loading";
import {loadData, mainLeadSlice} from "../../store/main_lead/slice";
import Table, {IColumn, IRow} from "../../lib/table/Table";
import {googleAnalyticsList} from "../../store/google";


export default function () {
    const {loading, counter, leads} = useAppSelector(state => state.mainLead)
    const {data: ga} = useAppSelector(state => state.googleAnalytics)
    const [date, setDate] = useState(new EDate())
    const dispatch = useAppDispatch()

    function refresh() {
        dispatch(googleAnalyticsList())
        dispatch(loadData(date.isoDate()))
    }

    useEffect(() => {
        refresh()
    }, [date])

    if (ELoadingStatus.initial === loading || !ga.length) return <Loading/>

    const columns: IColumn[] = [
        {label: 'id', type: 'text'},
        {label: 'google_id', type: 'text'},
        {label: 'время события', type: 'text'},
        {label: 'отправлено', type: 'text'},
    ]
    const data: IRow[] = []
    for (const lead of leads) {
        data.push({
            id: lead.id,
            cells: [
                lead.id,
                lead.google_id,
                new EDate(lead.become_main_lead_at).ruDateTime(),
                lead.main_lead_analytics_uploaded_at ? new EDate(lead.main_lead_analytics_uploaded_at).ruDateTime(): ''
            ]
        })
    }
    function changeDayLimit(day_limit:number) {
        // @ts-ignore
        dispatch(mainLeadSlice.actions.path({day_limit, id: counter?.id}))
    }

    return <Flex1 style={m2}>
        <Col style={{...fixWidth(350), ...fEnd, ...mb2}}>
            <DateField
                buttons={true}
                value={date}
                onChange={setDate}
                style={mv2}
            />
            <NumberField
                label={'Отправлено'}
                value={counter?.value ? counter.value : 0}
                editable={false}
            />
            <NumberField
                label={'Всего'}
                value={leads.length}
                editable={false}
            />
            <NumberField
                label={'Дневной лимит'}
                value={counter?.day_limit ? counter.day_limit : 0}
                onChange={changeDayLimit}
            />
        </Col>
        <Table
            columns={columns}
            data={data}
        />
    </Flex1>
}
