import {Months} from "../common";
import Month from "./Month";
import {bold} from "../../../lib/styles";

interface ISection {
    prefix: string
    title: string
    colTitleStyle: object
    colStyle: object
    year: number
}

export default function ({prefix, title, colTitleStyle, colStyle, year}: ISection) {
    return <>
        <tr>
            <td style={colTitleStyle} rowSpan={13}>{title}</td>
            <Month prefix={prefix} colStyle={colStyle} month={Months[0]} year={year}/>
        </tr>
        {Months.map((month, key) => {
            if (!key) return null
            return <tr key={key}>
                <Month prefix={prefix} colStyle={colStyle} month={month} year={year}/>
            </tr>
        })}
        <tr>
            <Month prefix={prefix} colStyle={{...colStyle, ...bold}} month={null} year={year}/>
        </tr>
    </>

}
