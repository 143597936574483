import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "./store/store";
import {AuthStatus, userGet, userSlice} from "./store/user";
import Header from "./page/wrapper/Header";
import {pages} from "./page/pages";
import {Flex1} from "./lib/markup/markup";

import {AppContext} from './contexts';
import Loading from "./lib/static/Loading";
import Login from "./page/Login";


function App() {
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.user)

    useEffect(() => {
        if (AuthStatus.initial === user.authStatus) dispatch(userGet())
    }, [user.authStatus]);

    if ([AuthStatus.initial, AuthStatus.loading].includes(user.authStatus)) return <Loading/>
    return (
        <Flex1>
            <AppContext.Provider
                value={{
                    navigation: {
                        navigate: (url: string) => dispatch(userSlice.actions.navigate(url)),
                        goBack: () => dispatch(userSlice.actions.goBack())
                    }
                }}>
                {user.authStatus !== AuthStatus.authorised ?
                    <Login/>
                    :
                    <>
                        <Header/>
                        <div style={{overflow: 'hidden'}}>
                            {pages.map(({
                                            Component,
                                            name, accessControl
                                        },
                                        key) => {
                                if (name !== user.currentPage) return null
                                if (accessControl
                                    && !user.data?.pages.filter(({name}) => user.currentPage === name).length) {
                                    return null
                                }
                                return <Component key={key}/>
                            })}
                        </div>
                    </>
                }
            </AppContext.Provider>
        </Flex1>
    );
}

export default App;
