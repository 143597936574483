import FA from "../static/FA";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {field, fixWidth, primary, primaryLight, secondary} from "../styles";
import {useState} from "react";


export interface IBtn {
    title?: string
    style?: any
    visible?: boolean
    onPress?: any
    icon?: IconDefinition | null
    iconSize?: SizeProp
    fontColor?: string
    type?: 'none' | 'primary' | 'secondary'
    width?: 'auto' | 'flex' | 'field'
    disabled?: boolean
}

const sBtn = {
    margin: 0,
    background: 'transparent',
    borderWidth: 0,
    borderColor: 'transparent',
    borderStyle: 'solid',
    height: field.height + 8,
    maxHeight: field.height + 8,
    minHeight: field.height + 8,
    borderRadius: field.borderRadius,
    color: '#000',
    cursor: 'pointer',
}


const defaultProps: IBtn = {
    title: 'button',
    type: 'none',
    style: {},
    visible: true,
    iconSize: 'lg',
    icon: null,
    onPress: null,
    width: 'auto',
    disabled: false
}

export function Btn(props: IBtn): JSX.Element | null {
    const [hover, setHover] = useState(false)
    if (props.visible === false) return null
    let style = {...sBtn}
    let className = 'btn-primary'
    switch (props.type) {
        case 'primary':
            style = {...style, background: hover ? primaryLight.color : primary.color, color: '#fff'};
            break;
        case 'secondary':
            style = {...style, background: secondary.color};
            className = 'btn-secondary'
            break;
    }

    style = {...style, ...props.style}

    switch (props.width) {
        case 'field':
            style = {...style, ...fixWidth(field.width+8)}
            break;
        case 'flex':
            // @ts-ignore
            style = {...style, width: '100%'}
            break;
        default:
            // @ts-ignore
            style = {...style, minWidth: 20}
    }
    if (props.icon) {
        return <button
            disabled={props.disabled}
            style={style}
            onClick={props.onPress}
        >
            <FA
                icon={props.icon}
                color={style.color}
                // @ts-ignore
                size={props.iconSize}
            />
        </button>
    }
    return <button onClick={props.onPress} style={style}>{props.title}</button>
}

Btn.defaultProps = defaultProps

export function BtnPrimary(props: IBtn): JSX.Element | null {
    return <Btn type={'primary'} {...props}/>
}


export function BtnSecondary(props: IBtn): JSX.Element | null {
    return <Btn type={'secondary'} {...props}/>
}


