import Users from "./users/Users";
import Profile from "./profile/Profile";
import Dashboard from "./dashboard/Dashboard";
import Integrations from "./integrations/Integrations";
import Reports from "./reports/Reports";
import Observer from "./observer/Observer";
import MainLead from "./main_lead/MainLead";


export const pages = [
    {name: 'Dashboard', Component: Dashboard, title: 'Dashboard', accessControl: true},
    {name: 'Integrations', Component: Integrations, title: 'Интеграции', accessControl: true},
    {name: 'Profile', Component: Profile, title: 'Профиль пользователя', accessControl: false},
    {name: 'Users', Component: Users, title: 'Пользователи', accessControl: true},
    {name: 'Reports', Component: Reports, title: 'Отчёты', accessControl: true},
    {name: 'Observer', Component: Observer, title: 'Observer', accessControl: true},
    {name: 'MainLead', Component: MainLead, title: 'MainLead', accessControl: true},
]
