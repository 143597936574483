import {IMarkup} from "./markup";


export default function ({
                             children = null,
                             visible = true,
                             style = {},
                             onPress = null,
                         }: IMarkup): JSX.Element | null {
    if (!visible) return null
    return <div style={{overflow: "scroll"}}>
        {children}
    </div>
}
