import {TextField} from "./inputs";
import {Btn, BtnPrimary} from "../buttons/Btn";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {stickLeft, stickRight} from "../styles";

interface ICreator {
    visible?: boolean
    onPress?: any
    placeholder?: string
    value?: string
    style?: object
    onChange?: any
}

export default function ({
                             visible = true,
                             onPress = null,
                             placeholder = 'placeholder',
                             value = '',
                             style = {},
                             onChange = null
                         }: ICreator): JSX.Element | null {
    if (!visible) return null
    return <div style={style}>
        <TextField value={value} placeholder={placeholder} style={stickRight} onChange={onChange}/>
        <BtnPrimary icon={faPlus} style={stickLeft} onPress={onPress}/>
    </div>
}
