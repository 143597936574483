import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DELETE, GET, IApiError, POST} from "../axios";
import {AuthStatus} from "../user";
import {
    initialObserverState,
    INotificationEmail,
    IObserver, IObserverObjectCreate, IObserverObjectCreated, IObserverObjectRemove,
    ISiteOfflineEvent,
    IWebSite,
    notificationEmailURL, siteOfflineEventURL,
    webSiteURL
} from "./consts";
import {ELoadingStatus} from "../common";
import {isEmailCorrect, removeFromArrayById} from "../../lib/sugar";
import {retry} from "@reduxjs/toolkit/query";

export const loadData = createAsyncThunk(
    'observer/loadData',
    async () => {
        const newState: IObserver = {...initialObserverState}
        newState.webSites = (await GET<IWebSite[]>(webSiteURL)).data
        newState.notificationEmails = (await GET<INotificationEmail[]>(notificationEmailURL)).data
        newState.siteOfflineEvents = (await GET<ISiteOfflineEvent[]>(siteOfflineEventURL)).data
        newState.siteOfflineEvents.sort((a, b) => {
            return a.created_at > b.created_at ? -1: 1
        })
        newState.loading = ELoadingStatus.ready
        return newState
    }
)

export const observerObjectCreate = createAsyncThunk(
    'observer/create',
    async ({objects, args}: IObserverObjectCreate) => {
        const url = getUrl(objects)
        const response = await POST(url, args)
        return {objects, object: response.data}
    }
)


export const observerSlice = createSlice({
    name: 'observer',
    initialState: initialObserverState,
    reducers: {
        remove: (state, action: PayloadAction<IObserverObjectRemove>) => {
            const {id, objects} = action.payload
            const url = `${getUrl(objects)}${id}/`
            DELETE(url)
            state[objects] = removeFromArrayById(state[objects], id)
        }
    },
    extraReducers: {
        [loadData.pending.type]: (state) => {
            state.loading = ELoadingStatus.loading
        },
        [loadData.rejected.type]: (state, action: IApiError) => {
            state.loading = ELoadingStatus.error
        },
        [loadData.fulfilled.type]: (state, action: PayloadAction<IObserver>) => {
            Object.assign(state, action.payload)
        },

        [observerObjectCreate.rejected.type]: (state, action: IApiError) => {
            state.loading = ELoadingStatus.error
        },
        [observerObjectCreate.fulfilled.type]: (state, action: PayloadAction<IObserverObjectCreated>) => {
            const {objects, object} = action.payload
            // @ts-ignore
            state[objects].push(object)
        },
    }
})

function getUrl(objects: string) {
    switch (objects) {
        case 'notificationEmails': return notificationEmailURL;
        case 'siteOfflineEvents': return siteOfflineEventURL
        default: return webSiteURL
    }
}


