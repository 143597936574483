import {btnWidth, field, fixHeight, fixWidth, fRow, stick, stickLeft, stickRight} from "../styles";
import {ChangeEvent, createRef, useEffect, useRef} from "react";
import {BtnPrimary} from "../buttons/Btn";
import {faChevronLeft, faChevronRight, faImage} from "@fortawesome/free-solid-svg-icons";
import {EDate} from "../sugar";

interface IField {
    label?: string
    onChange?: (arg0: any) => void
    onBlur?: (arg0: any) => void
    style?: object
    visible?: boolean
    editable?: boolean
}

interface ITextField extends IField {
    placeholder?: string
    maxLength?: number
    value?: string
    onKey?: any
    keyBoardType?: 'default' |
        'number-pad' |
        'decimal-pad' |
        'numeric' |
        'email-address' |
        'phone-pad' |
        'url'
}


export function TextField({
                              label = '',
                              placeholder = 'placeholder',
                              onChange = () => {
                              },
                              style = {},
                              visible = true,
                              maxLength = 10000,
                              value = '',
                              onKey = null,
                              keyBoardType = 'default',
                              onBlur = () => {
                              },
                              editable = true
                          }: ITextField): JSX.Element | null {
    if (!visible) return null
    // const ref = useRef();
    // useEffect(() => {
    //     ref.dispatchEvent(new KeyboardEvent('keypress', {
    //         key: 'Enter',
    //     }));
    // }, []);

    return <label>{label ? label + ': ' : ''}
        <input
            disabled={!editable}
            style={{...field, ...style}}
            placeholder={placeholder}
            onChange={e => onChange(e.target.value)}
            maxLength={maxLength}
            // @ts-ignore
            onBlur={onBlur}
            value={value}
            // @ts-ignore
            onKeyPress={({nativeEvent: {key: keyValue}}) => {
                if (onKey && onKey[keyValue]) onKey[keyValue](value)
            }}
        />
    </label>
}

interface INumberField extends IField {
    value: number
    max?: number
    min?: number
    step?: number
}

export function NumberField({
                                label = '',
                                visible = true,
                                onChange = () => {
                                },
                                onBlur = () => {
                                },
                                value = 0,
                                style = {},
                                min = 0,
                                max = 999999,
                                step = 1,
                                editable = true
                            }: INumberField): JSX.Element | null {
    if (!visible) return null
    return <label style={{whiteSpace: 'nowrap'}}>{label ? label + ': ' : ''}
        <input
            style={{...field, ...style}}
            onChange={e => {
                onChange(+e.target.value)
            }}
            type={'number'}
            value={+value}
            max={max}
            min={min}
            step={step}
            disabled={!editable}
            onBlur={e => onBlur(+e.target.value)}
        />
    </label>
}

interface IColorField extends IField {
    value?: string
}

export function ColorField({
                               label = '',
                               visible = true,
                               onChange = () => {
                               },
                               value = '#000',
                               style = {},
                               editable = true
                           }: IColorField): JSX.Element | null {
    if (!visible) return null
    return <label style={{whiteSpace: 'nowrap'}}>{label ? label + ': ' : ''}
        <input
            style={{...field, ...fixHeight(field.height + 8), ...style}}
            onChange={e => onChange(e.target.value)}
            type={'color'}
            value={value}
        />
    </label>
}

interface IDateField extends IField {
    value?: EDate | null
    buttons?: boolean
}

export function DateField({
                              label = '',
                              visible = true,
                              onChange = () => {
                              },
                              value = null,
                              style = {},
                              editable = true,
                              buttons = false
                          }: IDateField): JSX.Element | null {
    if (!visible) return null
    let fieldStyle = {...fixHeight(field.height+4), ...style}
    if (buttons) {
        fieldStyle = {...fieldStyle, ...stick, ...fixWidth(field.width-btnWidth.minWidth*1.2)}
    }
    else fieldStyle = {...fieldStyle, ...field}
    return <label style={{whiteSpace: 'nowrap'}}>{label ? label + ': ' : ''}
        <BtnPrimary
            visible={buttons}
            style={stickRight}
            onPress={() => {
                const date = value ? new EDate(value): new EDate()
                date.change({days: -1})
                onChange(date)
            }}
            icon={faChevronLeft}
        />
        <input
            disabled={!editable}
            style={fieldStyle}
            onChange={e => onChange(new EDate(e.target.value))}
            type={'date'}
            value={value ? value.isoDate() : ''}
        />
        <BtnPrimary
            visible={buttons}
            style={stickLeft}
            onPress={() => {
                const date = value ? new EDate(value): new EDate()
                date.change({days: 1})
                onChange(date)
            }}
            icon={faChevronRight}
        />
    </label>
}

interface IFileField extends IField {
    value?: any
    accept?: string
    title?: string
    icon?: typeof faImage | null
    width?: 'field' | 'auto' | 'flex'
    style?: object
}

export function FileField({
                              label = '',
                              visible = true,
                              onChange = () => {
                              },
                              value = '',
                              accept = "image/*",
                              style = {},
                              title = '',
                              icon = null,
                              width = 'auto',
                              editable = true
                          }: IFileField): JSX.Element | null {
    if (!visible) return null

    function uploadFile(e: ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files
        if (!fileList) return
        onChange(fileList[0])
    }

    function openLoader() {
        // @ts-ignore
        inputRef.current.click()
    }

    const inputRef = createRef()

    return <label style={{whiteSpace: 'nowrap'}}>{label ? label + ': ' : ''}
        <BtnPrimary
            icon={icon}
            title={title}
            style={style}
            onPress={openLoader}
            width={width}
        />
        <input
            // @ts-ignore
            ref={inputRef}
            hidden={true}
            type={"file"}
            accept={accept}
            multiple={false}
            onChange={uploadFile}
            value={value ? value : undefined}
        />
    </label>
}
