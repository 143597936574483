import {bold} from "../../../lib/styles";
import {IFact} from "../../contexts";
import {getAverageTicket, getCPL, getCPS, getCR, getDPP} from "../common";
import {Number, Rub} from "../../../lib/static/text";

interface ITotal {
    titleStyle: object
    advTotal: number
    advTotalWithoutVat: number
    fact: IFact
}

export default function ({titleStyle, advTotalWithoutVat, advTotal, fact}: ITotal) {
    const totalStyle = {...bold, ...titleStyle}
    return <tr>
        <td style={{...totalStyle, textAlign: 'right'}} colSpan={2}>ИТОГО:</td>
        <td style={totalStyle}><Number>{fact.leadsCount}</Number></td>
        <td style={totalStyle}>{getCPL(advTotal, fact.leadsCount)}</td>
        <td style={totalStyle}><Rub>{advTotal}</Rub></td>
        <td style={totalStyle}>{getCR(fact.leadSuccessCount, fact.leadsCount)}</td>
        <td style={totalStyle}><Number>{fact.leadSuccessCount}</Number></td>
        <td style={totalStyle}>{getCPS(advTotal, fact.leadSuccessCount)}</td>
        <td style={totalStyle}>{getAverageTicket(fact.income, fact.leadSuccessCount)}</td>
        <td style={totalStyle}><Rub>{fact.income}</Rub></td>
        <td style={totalStyle}>{getDPP(advTotal, fact.income)}</td>
    </tr>
}
