import {AnyAction, combineReducers, configureStore} from "@reduxjs/toolkit";
import {appSlice, initialAppState} from "./app";
import {storage} from "./storage";
import {initialUserState, userSlice} from "./user";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {initialPageAccess, pageAccessSlice} from "./page_access";
import {amoCrmSlice, initialAmoCrm} from "./amo_crm";
import {googleAnalyticsSlice, initialIGoogleAnalytics} from "./google";
import {initialYandexApp, yandexApp} from "./yandex";
import {initialLogs, logSlice} from "./logs";
import {firstTouchReportSlice, initialFirstTouchReport} from "./reports/first_touch";
import {observerSlice} from "./observer/slice";
import {initialObserverState} from "./observer/consts";
import {dashboardSlice, initialDashboardState} from "./dashboard/slice";
import {mainLeadSlice} from "./main_lead/slice";
import {initialMainLeadState} from "./main_lead/interfaces";


const appReducer = combineReducers({
    app: appSlice.reducer,
    user: userSlice.reducer,
    pageAccess: pageAccessSlice.reducer,
    AmoCrm: amoCrmSlice.reducer,
    googleAnalytics: googleAnalyticsSlice.reducer,
    yandexApp: yandexApp.reducer,
    logs: logSlice.reducer,
    firstTouchReport: firstTouchReportSlice.reducer,
    observer: observerSlice.reducer,
    dashboard: dashboardSlice.reducer,
    mainLead: mainLeadSlice.reducer
})

const rootReducer = (state: object, action: AnyAction) => {
    if (action.type === 'logout') {
        storage.clear()
        const newState = {
            ...state,
            app: initialAppState,
            user: initialUserState,
            pageAccess: initialPageAccess,
            AmoCrm: initialAmoCrm,
            googleAnalytics: initialIGoogleAnalytics,
            logs: initialLogs,
            firstTouchReport: initialFirstTouchReport,
            observer: initialObserverState,
            dashboard: initialDashboardState,
            yandexApp: initialYandexApp,
            mainLead: initialMainLeadState
        }
        // @ts-ignore
        return appReducer(newState, action)
    }
    // @ts-ignore
    return appReducer(state, action)
}

export const setupStore = () => {
    return configureStore({
        // @ts-ignore
        reducer: rootReducer,
        // @ts-ignore
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


