import {Col, Flex1, Row} from "../../lib/markup/markup";
import {
    absolute,
    bgPrimary,
    bgSecondary, bgSecondaryLight,
    bgWhite,
    borderTable,
    danger,
    flex1, mr3,
    mt1,
    mt3,
    p1,
    stickLeft,
    stickRight
} from "../../lib/styles";
import {BtnPrimary} from "../../lib/buttons/Btn";
import {DashboardContext} from "../contexts";
import React, {useContext, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {EDate, getObjectById, getObjectIndexById, zfill} from "../../lib/sugar";
import {NumberField} from "../../lib/fields/inputs";
import {H3, Rub, TextMain} from "../../lib/static/text";
import BtnToggle from "../../lib/buttons/BtnToggle";
import {Simulate} from "react-dom/test-utils";
import change = Simulate.change;
import {ILeadChange} from "../../store/dashboard/consts";
import {dashboardSlice} from "../../store/dashboard/slice";

export default function () {
    const {detailWindow, updateState, pipeline, month, year} = useContext(DashboardContext)
    const {statuses, leads} = useAppSelector(state => state.dashboard)
    const windowDate = new EDate(year, month, detailWindow.day).isoDate()
    const [mode, setMode] = useState('created_at')
    const dispatch = useAppDispatch()
    function openInAmoCRM(id: string) {
        window.open(`https://lofthall.amocrm.ru/leads/detail/${id}`)
    }

    const tdStyle = {
        ...p1,
        ...borderTable,
        ...bgWhite
    }

    const thStyle = {
        ...tdStyle,
        ...bgPrimary,
        color: '#fff'
    }

    function closeWindow() {
        updateState({detailWindow: {...detailWindow, visible: false, day: ''}})
    }

    function changeLead(args: ILeadChange) {
        console.log(args, '...............')
        dispatch(dashboardSlice.actions.leadPatch(args))
    }
    if (!detailWindow.visible) return null
    // @ts-ignore
    return <div style={{
        // @ts-ignore
        position: 'absolute',
        width: '96%',
        height: '90%',
        ...bgSecondaryLight,
        padding: 10,
        overflow: 'scroll'
    }}>
        <Row>
            <Row>
                <H3 style={{...mr3, ...mt1}}>{`${detailWindow.day}.${zfill(month + 1)}.${year}`}</H3>
                <BtnToggle
                    title={`открытые`}
                    style={stickRight}
                    onPress={() => setMode('created_at')}
                    value={mode === 'created_at'}
                />
                <BtnToggle
                    title={`закрытые`}
                    style={stickLeft}
                    onPress={() => setMode('closed_at')}
                    value={mode === 'closed_at'}
                />
            </Row>
            <BtnPrimary title={'закрыть'} onPress={closeWindow}/>
        </Row>
        <TextMain style={{...mt3}}>Клик по id или имени - откроет сделку в amoCRM</TextMain>
        <TextMain style={{...mt3}}>Если цена введена вручную - данные из amoCRM игнорируются</TextMain>
        <Col style={mt3}>
            <table>
                <thead>
                <tr>
                    <th style={thStyle}>id</th>
                    <th style={thStyle}>имя</th>
                    <th style={thStyle}>открыта</th>
                    <th style={thStyle}>закрыта</th>
                    <th style={thStyle}>статус</th>
                    <th style={thStyle}>цена из amoCRM</th>
                    <th style={thStyle}>цена ручной ввод</th>
                </tr>
                </thead>
                <tbody>
                {leads.map((lead, key) => {
                    if (pipeline && pipeline.id !== lead.pipeline) return null
                    // @ts-ignore
                    if (new EDate(lead[mode]).isoDate() !== windowDate) return null
                    const status = getObjectById(statuses, lead.status).name
                    return <tr key={key}>
                        <td style={tdStyle} onClick={() => openInAmoCRM(lead.id)}>{lead.id}</td>
                        <td style={tdStyle} onClick={() => openInAmoCRM(lead.id)}>{lead.name}</td>
                        <td style={tdStyle}>{new EDate(lead.created_at).ruDate()}</td>
                        <td style={tdStyle}>{lead.closed_at ? new EDate(lead.closed_at).ruDate() : ''}</td>
                        <td style={tdStyle}>{status}</td>
                        <td style={tdStyle}><Rub>{lead.price}</Rub></td>
                        <td style={tdStyle}>
                            <PriceManual
                                value={lead.price_manual_input}
                                onChange={(price_manual_input) => changeLead({id: lead.id, price_manual_input})}
                            />
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </Col>
    </div>
}

interface IPriceManual {
    value: number
    onChange: (value: number) => void
}
function PriceManual(props: IPriceManual) {
    const [value, setValue] = useState(+props.value)
    return <NumberField value={value} onChange={setValue} onBlur={() => props.onChange(+value)}/>
}
