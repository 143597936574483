import {Modes, Periods} from "../common";
import {DashboardContext} from "../../contexts";
import {useContext} from "react";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';
import {useAppSelector} from "../../../store/store";
import {EDate} from "../../../lib/sugar";
import {Flex1} from "../../../lib/markup/markup";
import SummaryChart from "./summary_chart/SummaryChart";


export const colors = [
    'red',
    'green',
    'blue',
    'orange',
    'black',
    'yellow'
]

export default function (): JSX.Element | null {
    const {mode, year, month, daysOnMonth, period} = useContext(DashboardContext)
    const {leads, pipelines} = useAppSelector(state => state.dashboard)
    if (mode !== Modes.chart || period !== Periods.month) return null
    let prepare = {}
    const lines = {}
    const pipeline_names = {}
    for (let pipeline of pipelines) {
        // @ts-ignore
        lines[pipeline.name] = 0
        // @ts-ignore
        pipeline_names[pipeline.id] = pipeline.name
    }
    for (let n = 1; n < daysOnMonth + 1; n++) {
        // @ts-ignore
        prepare[n] = {
            name: n,
            ...lines
        }
    }
    for (let lead of leads) {
        const date = new EDate(lead.created_at)
        if (date.getMonth() !== month || date.getFullYear() !== year) continue
        // @ts-ignore
        const key = pipeline_names[lead.pipeline]
        // @ts-ignore
        prepare[date.getDate()][key]++
    }
    const data: object[] = []
    for (let d in prepare) { // @ts-ignore
        data.push(prepare[d])
    }

    return <Flex1>
        <LineChart
        width={window.innerWidth - 30}
        height={400}
        data={data}
        margin={{top: 15, right: 20, left: 10, bottom: 5}}
    >
        <XAxis dataKey="name"/>
        <YAxis/>
        <Tooltip/>
        <CartesianGrid stroke="#f5f5f5"/>
        <Legend />
        {pipelines.map((pipeline, key) => {
            return <Line type="monotone" dataKey={pipeline.name} stroke={colors[key]} key={key}/>
        })}
    </LineChart>
        <SummaryChart/>
    </Flex1>
}

