
import AmoCrmRow from "./AmoCrmRow";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {useEffect, useState} from "react";
import {amoCrmCreate, amoCrmList, AmoCrmStatus} from "../../../store/amo_crm";
import Loading from "../../../lib/static/Loading";
import {Flex1, Row} from "../../../lib/markup/markup";
import {fStart, m2, ml1, ml2, mr2, mt2} from "../../../lib/styles";


export default function (): JSX.Element | null {
    const dispatch = useAppDispatch()
    const {data, status} = useAppSelector(state => state.AmoCrm)

    useEffect(() => {
        if (status === AmoCrmStatus.initial) {
            dispatch(amoCrmList())
        }
    }, []);

    if ([AmoCrmStatus.loading, AmoCrmStatus.initial].includes(status)) return <Loading/>
    return <Flex1 style={{...fStart, ...ml2}}>
        <Row>
            {data.map((instance, key) => {
                return <AmoCrmRow key={key} {...instance}/>
            })}
        </Row>
    </Flex1>
}

