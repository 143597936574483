import {ELoadingStatus} from "../common";


export interface ILead {
    id: string
    google_id: string
    visited: boolean
    main_lead_analytics_uploaded_at: string
    become_main_lead_at: string
}

export interface IGoogleMainLeadDayCounter {
    id: number
    acc: number
    day: string
    value: number
    day_limit: number
}

export interface IGoogleMainLeadDayCounterPath {
    id: number
    day_limit?: number
}

export interface IMainLeadState {
    loading: ELoadingStatus
    leads: ILead[]
    counter: IGoogleMainLeadDayCounter | null
}

export const initialMainLeadState: IMainLeadState = {
    loading: ELoadingStatus.initial,
    leads: [],
    counter: null
}

export const googleMainLeadDayCounterURL = 'google/GoogleMainLeadDayCounters/'
