import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {amoCrmSlice} from "../../store/amo_crm"; // Import css


export interface IConfirmAlert {
    title?: string
    message?: string
    yes?: () => void
    escape?: () => void
    remove?: () => void
}


export default function (options: IConfirmAlert) {
    if (options.yes && options.remove) throw `You can't use "yes" and "remove" arguments at the same time`

    const buttons = [
        {
            label: options.remove ? 'удалить': 'да',
            onClick: options.remove ? options.remove : options.yes
        },
        {
            label: 'отмена',
            onClick: options.escape
        }
    ]
    confirmAlert({
        title: options.title,
        message: options.message,
        buttons
    });
}
