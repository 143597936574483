import {Col, Row} from "../../lib/markup/markup";
import AmoCrm from "./amo_crm/AmoCrm";
import Google from "./google/Google";
import Yandex from "./yandex/Yandex";
import Maker, {IField} from "../../lib/fields/Maker";
import {fixWidth, mt2, mt4} from "../../lib/styles";
import {useAppDispatch} from "../../store/store";
import {amoCrmCreate} from "../../store/amo_crm";
import {googleAnalyticsCreate} from "../../store/google";
import {yandexAppCreate} from "../../store/yandex";


export default function () {
    const dispatch = useAppDispatch()
    function create(state: {name: string, integrationType: 'amocrm'|'google'| 'yandex'}) {
        const {name, integrationType} = state
        switch (integrationType) {
            case "amocrm":
                dispatch(amoCrmCreate({name}))
                break
            case 'google':
                dispatch(googleAnalyticsCreate({name}))
                break
            case 'yandex':
                dispatch(yandexAppCreate({name}))
                break
        }
    }
    const items = [
        // {label: 'amoCRM', value: 'amocrm'},
        // {label: 'google', value: 'google'},
        {label: 'yandex', value: 'yandex'}
    ]
    return <Maker
            fields={[
                {placeholder:'имя интеграции', type: 'text', field: 'name', value: ''},
                {type: 'select', field: 'integrationType', value: 'yandex', items}
            ]}
            create={create}
        />
}
