import {bgDanger, bgPrimary, bold, fCenter, fixHeight, fontMain, fontTitle, heightNormal, jCenter} from "../styles";
import {retry} from "@reduxjs/toolkit/query";
import {numberWithSpaces, round} from "../sugar";

interface IText {
    visible?: boolean
    children: string | null | number | undefined
    style?: object | null
}

export function TextMain({visible = true, children, style = {}}: IText): JSX.Element | null {
    if (!visible) return null
    // @ts-ignore
    return <div style={{
        display: 'flex',
        whiteSpace: 'normal',
        ...fontMain,
        ...style
    }}>
        {children}
    </div>
}


export function H3({visible = true, children, style = {}}: IText): JSX.Element | null {
    if (!visible) return null
    // @ts-ignore
    return <div style={{
        display: 'flex',
        ...fCenter,
        ...fontMain,
        ...bold,
        ...heightNormal,
        ...style,
    }}>{children}</div>
}

export function H2({visible = true, children, style = {}}: IText): JSX.Element | null {
    if (!visible) return null
    // @ts-ignore
    return <div style={{display: 'flex', ...fCenter, ...fontTitle, ...style}}>{children}</div>
}

export function H1({visible = true, children, style = {}}: IText): JSX.Element | null {
    if (!visible) return null
    // @ts-ignore
    return <div style={{display: 'flex', ...fCenter, ...fontTitle, ...bold, ...style}}>{children}</div>
}

interface INumber extends IText {
    decimal?: number
}

export function Rub({visible = true, children, style = {}, decimal = 2}: INumber) {
    if (!visible) return null
    const str = numberWithSpaces(children, decimal) + ' ₽'
    return <TextMain style={style}>{str}</TextMain>
}

export function Percent({visible = true, children, style = {}, decimal = 2}: INumber) {
    if (!visible) return null
    const str = numberWithSpaces(children, decimal) + ' %'
    return <TextMain style={style}>{str}</TextMain>
}

export function Number({visible = true, children, style = {}, decimal = 2}: INumber) {
    if (!visible) return null
    return <TextMain style={style}>{numberWithSpaces(children, decimal)}</TextMain>
}

