import {Flex1} from "../../../../lib/markup/markup";
import SummarySuccessLeadsChart from "./SummarySuccessLeadsChart";
import SummaryLeadsChart from "./SummaryLeadsChart";
import SummaryIncomeChart from "./SummaryIncomeChart";


export default function () {
    return <Flex1>
       <SummaryLeadsChart/>
        <SummarySuccessLeadsChart/>
        <SummaryIncomeChart/>
    </Flex1>
}
