import {Col} from "../../../lib/markup/markup";
import {useContext} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {dashboardSlice, planCreate} from "../../../store/dashboard/slice";
import {getObjectById, zfill} from "../../../lib/sugar";
import {
    fixWidth,
     p1,
    pt3
} from "../../../lib/styles";
import {DashboardContext} from "../../contexts";
import {Modes, Months, rowTitleBG} from "../common";
import {IPlanCreate, IPlanPatch} from "../../../store/dashboard/consts";
import Table, {IColumn, IRow} from "../../../lib/table/Table";
import Maker from "../../../lib/fields/Maker";

interface IStateChange {
    month?: number
    year?: number
    leads_count_positive?: number
    leads_count_negative?: number
    income_positive?: number
    income_negative?: number
    success_leads_positive?: number
    success_leads_negative?: number
    advertisement_budget?: number
    pipeline?: number
}

export default function () {
    const {month, year, mode, pipeline} = useContext(DashboardContext)
    const {pipelines, plans, creationError} = useAppSelector(state => state.dashboard)
    const dispatch = useAppDispatch()

    function create(args: IPlanCreate) {
        dispatch(planCreate(args))
    }

    function remove(id: string) {
        dispatch(dashboardSlice.actions.planRemove(id))
    }

    function onChange(args: IPlanPatch) {
        dispatch(dashboardSlice.actions.planPatch(args))
    }

    if (mode !== Modes.plan) return null


    const style = {...p1, ...fixWidth(120)}
    const columns: IColumn[] = [
        {label: 'воронка', style, type: 'text'},
        {label: 'дата', style: {...p1, ...fixWidth(60)}, type: 'text'},
        {label: 'заявки (негативный)', style, type: 'numberField', onChange, fieldName: 'leads_count_negative'},
        {label: 'заявки (позитивный)', style, type: 'numberField', onChange, fieldName: 'leads_count_positive'},
        {
            label: 'успешно реализовано (негативный)',
            style,
            type: 'numberField',
            onChange,
            fieldName: 'success_leads_negative'
        },
        {
            label: 'успешно реализовано (позитивный)',
            style,
            type: 'numberField',
            onChange,
            fieldName: 'success_leads_positive'
        },
        {label: 'доход (негативный)', style, type: 'numberField', onChange, fieldName: 'income_negative'},
        {label: 'доход (позитивный)', style, type: 'numberField', onChange, fieldName: 'income_positive'},
        {label: 'расход', style, type: 'numberField', onChange, fieldName: 'advertisement_budget'},
        {label: '', style, type: 'remove'},
    ]

    const data: IRow[] = []
    for (const plan of plans) {
        if (pipeline && pipeline.id !== plan.pipeline) continue
        if (year !== plan.year) continue
        let planPipeline = getObjectById(pipelines, plan.pipeline)
        data.push({
            id: plan.id,
            cells: [
                planPipeline?.name || '',
                `${zfill(plan.month+1)}.${plan.year}`,
                plan.leads_count_negative,
                plan.leads_count_positive,
                plan.success_leads_negative,
                plan.success_leads_positive,
                plan.income_negative,
                plan.income_positive,
                plan.advertisement_budget
            ]
        })
    }

    return <Col style={pt3}>
        <Maker
            error={creationError}
            style={fixWidth(500)}
            vertical={true}
            fields={[
                {
                    label: 'воронка',
                    field: 'pipeline',
                    type: 'select',
                    items: pipelines.map(pipeline => {
                        return {label: pipeline.name, value: pipeline.id}
                    }),
                    value: pipeline ? pipeline.id : pipelines[0].id,
                    required: false
                },
                {label: 'месяц', field: 'month', type: 'select', items: Months, value: month, required: false},
                {label: 'год', field: 'year', type: 'number', value: year, required: true},
                {
                    label: 'заявки (негативный)',
                    field: 'leads_count_negative',
                    required: false,
                    type: 'number',
                    value: 0
                },
                {
                    label: 'заявки (позитивный)',
                    field: 'leads_count_positive',
                    required: false,
                    type: 'number',
                    value: 0
                },
                {
                    label: 'успешно реализовано (негативный)',
                    field: 'success_leads_negative',
                    required: false,
                    type: 'number',
                    value: 0
                },
                {
                    label: 'успешно реализовано (позитивный)',
                    field: 'success_leads_positive',
                    required: false,
                    type: 'number',
                    value: 0
                },
                {label: 'доход (негативный)', field: 'income_negative', required: false, type: 'number', value: 0},
                {label: 'доход (позитивный)', field: 'income_positive', required: false, type: 'number', value: 0},
                {label: 'расход', field: 'advertisement_budget', required: false, type: 'number', value: 0}
            ]}
            create={create}
        />
        <Table columns={columns} data={data} headerStyle={rowTitleBG} remove={remove}/>
    </Col>
}
