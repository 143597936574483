import {
    googleAnalyticsCheck,
    googleAnalyticsSlice,
    IGoogleAnalytics,
    IGoogleAnalyticsPath, removeApiKey, uploadApiKey
} from "../../../store/google";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import confirmAlert from "../../../lib/modals/confirmAlert";
import {IntegrationTitle, IntegrationWrapper} from "../../common";
import {FileField, NumberField, TextField} from "../../../lib/fields/inputs";
import {mv2} from "../../../lib/styles";
import {BtnSecondary} from "../../../lib/buttons/Btn";
import {TextMain} from "../../../lib/static/text";


export default function (props: IGoogleAnalytics): JSX.Element | null {
    const dispatch = useAppDispatch()
    const {keyMessage, keyMessageStyle} = useAppSelector(state => state.googleAnalytics)

    const fields = ['name', 'view_id']
    function patch(args: IGoogleAnalyticsPath) {
        dispatch(googleAnalyticsSlice.actions.patch(args))
    }

    function checkConnection() {
        dispatch(googleAnalyticsCheck(props.id))
    }
    function uploadKey(api_key:File) {
        dispatch(uploadApiKey({id: props.id, api_key}))
        checkConnection()
    }

    function removeKey() {
        confirmAlert({
            title: 'удалить ключ?',
            remove: () => dispatch(removeApiKey(props.id))
        })
    }

    function remove() {
        dispatch(dispatch(googleAnalyticsSlice.actions.remove(props.id)))
    }

    return <IntegrationWrapper online={props.online}>
        <IntegrationTitle title={'google'} online={props.online} remove={remove}/>
        {fields.map((field, key) => {
            return <TextField
                onBlur={checkConnection}
                label={field}
                key={key}
                placeholder={field}
                // @ts-ignore
                value={props[field]}
                onChange={(value) => patch({id: props.id, [field]: value})}
            />
        })}
        <FileField
            style={mv2}
            title={'загрузить api_key.json'}
            width={'field'}
            onChange={uploadKey}
            visible={!props.api_key}
            accept={'json'}
        />
        <BtnSecondary
            style={mv2}
            title={'удалить api_key.json'}
            onPress={removeKey}
            width={'field'}
            visible={!!props.api_key}
        />
        <TextMain style={{...keyMessageStyle, mv2}}>{keyMessage}</TextMain>
        <NumberField
            label={'MAIN LEAD day limit'}
            value={props.main_lead_day_limit}
        />
    </IntegrationWrapper>
}

