import {Row} from "../../lib/markup/markup";
import {TextMain} from "../../lib/static/text";
import {useAppDispatch} from "../../store/store";
import {EUserStatus, IUser, userSlice} from "../../store/user";
import {danger, p2, pl3} from "../../lib/styles";


export default function (props: IUser): JSX.Element | null {
    const dispatch = useAppDispatch()

    function goToUser() {
        dispatch(userSlice.actions.editingUser(props.id))
    }
    const statusColor = props.status === EUserStatus.banned ? danger : {}

    return <Row onPress={goToUser} style={p2}>
        <TextMain style={{...statusColor}}>{props.id}</TextMain>
        <TextMain style={{...pl3, ...statusColor}}>{props.username}</TextMain>
    </Row>
}


