import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ELoadingStatus} from "../common";
import {GET, IApiError, PATCH, POST} from "../axios";
import {IAmoCrmPatch} from "../amo_crm";


export enum EFirstReportStatus {
    READY = 'ready',
    LOADING_FROM_AMOCRM = 'loading from amoCRM',
    AMOCRM_ERROR = 'amoCRM error',
    LOADING_FROM_GOOGLE = 'loading from google',
    GOOGLE_ERROR = 'google error',
    XLS_ERROR = 'xls error',
    SERVER_ERROR = 'server error',
    REQUESTING = 'requesting'
}

export interface IFirstTouchReport {
    id: number
    status: EFirstReportStatus
    file: string
}

export interface IFirstTouchReportSlice {
    report: IFirstTouchReport
    loading: ELoadingStatus
}

export const initialFirstTouchReport: IFirstTouchReportSlice = {
    report: {id: 1, status: EFirstReportStatus.REQUESTING, file: ''},
    loading: ELoadingStatus.initial,
}

const FirstTouchReportURL = 'reports/FirstTouchReports/'

export const firstTouchReportGet = createAsyncThunk(
    'FirstTouchReport/get',
    async () => {
        const response = await GET<IFirstTouchReport>(FirstTouchReportURL + '1/')
        return response.data
    }
)


export interface IFirstTouchMake {
    start: string,
    stop: string
}

export const firstTouchReportSlice = createSlice({
    name: 'firstTouchReport',
    initialState: initialFirstTouchReport,
    reducers: {
        reset: (state) => {
            POST(FirstTouchReportURL + 'reset/', {})
            state.report.status = EFirstReportStatus.READY
            state.report.file = ''
        },
        make: (state, action) => {
            const {start, stop} = action.payload
            POST<IFirstTouchReport>(FirstTouchReportURL + 'make/', {start, stop})
            state.report.status = EFirstReportStatus.REQUESTING
        }
    },
    extraReducers: {
        [firstTouchReportGet.rejected.type]: (state, action: IApiError) => {
            state.loading = ELoadingStatus.error
        },
        [firstTouchReportGet.fulfilled.type]: (state, action: PayloadAction<IFirstTouchReport>) => {
            state.report = action.payload
            state.loading = ELoadingStatus.ready
        }
    }
})

export function readyToRequest(status: EFirstReportStatus) {
    return [
        EFirstReportStatus.AMOCRM_ERROR,
        EFirstReportStatus.GOOGLE_ERROR,
        EFirstReportStatus.XLS_ERROR,
        EFirstReportStatus.READY,
        EFirstReportStatus.SERVER_ERROR
    ].includes(status)
}

