import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GET, IApiError, PATCH, POST, token} from "./axios";
import {storage} from "./storage";
import {arrayPushOrRemoveIfExists, getObjectIndexById, isEmailCorrect} from "../lib/sugar";
import {pages} from "../page/pages";
import {danger, primary, success} from "../lib/styles";


interface IPageAccess {
    id: number
    name: string
}

export enum EUserStatus {
    active = 'active',
    banned = 'banned'
}

export interface IUser {
    id: number
    username: string
    pages: IPageAccess[]
    status: EUserStatus
}

export interface IUserPatch {
    id: number
    username?: string
    pages?: []
    status?: EUserStatus
}

export enum AuthStatus {
    initial,
    loading,
    unauthorized,
    codeRequesting,
    codeRequested,
    error,
    authorising,
    authorised
}


export interface IUserMessage {
    text: string
    style: typeof primary
}

export interface IUserState {
    data: IUser | null // current user
    authStatus: AuthStatus
    otherUsers: IUser[]
    loading: boolean
    editingUserId: number
    emailMessage: IUserMessage | null
    codeMessage: IUserMessage | null
    canPasswordRequestInSeconds: number
    userCreateError: string
    currentPage: string,
    pageStack: [],
    canGoBack: boolean
}

export const initialUserState: IUserState = {
    data: null,
    authStatus: AuthStatus.initial,
    otherUsers: [],
    loading: false,
    editingUserId: -1,
    codeMessage: null,
    emailMessage: null,
    canPasswordRequestInSeconds: 0,
    userCreateError: '',
    currentPage: pages[0].name,
    pageStack: [],
    canGoBack: false
}

const WRONG_EMAIL = 'wrongEmail'
const WRONG_CODE = 'wrongCode'
const CODE_LENGTH = 6
const USERS_URL = 'main/users/'

export const userGet = createAsyncThunk(
    'user/current',
    async () => {
        const response = await GET<IUser>(`${USERS_URL}current/`)
        return response.data
    }
)

type TUserLogin = {
    username: string
    password: string
}

export const requestPassword = createAsyncThunk(
    'user/requestPassword',
    async (username: string) => {
        if (!isEmailCorrect(username)) throw WRONG_EMAIL
        const response = await POST(`${USERS_URL}request_password/`, {username}, false)
        return response.data
    }
)

export const userLogin = createAsyncThunk(
    'user/login',
    async (args: TUserLogin) => {
        if (!isEmailCorrect(args.username)) throw WRONG_EMAIL
        if (args.password.length !== CODE_LENGTH || isNaN(+args.password)) throw WRONG_CODE
        const response = await POST('token_obtain/', args, false)
        if (response.status === 200) {
            await storage.set(token.refresh, response.data.refresh, false)
            await storage.set(token.access, response.data.access, false)
            const user_response = await GET<IUser>(`${USERS_URL}current/`)
            return user_response.data
        }
        throw '401'
    }
)
export const userCreate = createAsyncThunk(
    'user/create',
    async (email: string) => {
        if (!isEmailCorrect(email)) throw WRONG_EMAIL
        const response = await POST(USERS_URL, {username: email}, false)
        return response.data
    }
)

export const usersAll = createAsyncThunk(
    'user/All',
    async () => {
        const response = await GET(USERS_URL)
        return response.data
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        navigate: (state, action: PayloadAction<string>) => {
            state.canGoBack = state.pageStack.length > 0
            state.currentPage = action.payload
        },
        goBack: (state) => {
            if (state.pageStack.length > 0) {
                state.currentPage = state.pageStack[state.pageStack.length - 1]
                state.pageStack.pop()
                state.canGoBack = state.pageStack.length > 0
            }
        },
        patch: (state, action: PayloadAction<IUserPatch>) => {
            const id = action.payload.id
            // @ts-ignore
            delete action.payload.id
            PATCH(`${USERS_URL}${id}/`, action.payload)
            // @ts-ignore
            if (id === state.data.id) state.data = {...state.data, ...action.payload}
            else {
                const index = getObjectIndexById(state.otherUsers, id)
                state.otherUsers[index] = {...state.otherUsers[index], ...action.payload}
            }
        },
        editingUser: (state, action: PayloadAction<number>) => {
            state.editingUserId = action.payload
            state.currentPage = 'Profile'
        },
        pageAccess: (state,
                     action: PayloadAction<{
                         pageAccess: IPageAccess,
                         value: boolean,
                         user_id: number
                     }>
        ) => {
            const {pageAccess, value, user_id} = action.payload
            PATCH(`main/pageAccess/${pageAccess.id}/access/`, {user_id, value})
            if (user_id === state.data?.id) state.data.pages = arrayPushOrRemoveIfExists(state.data.pages, pageAccess)
            else {
                const index = getObjectIndexById(state.otherUsers, user_id)
                state.otherUsers[index].pages = arrayPushOrRemoveIfExists(state.otherUsers[index].pages, pageAccess)
            }
        },
    },
    extraReducers: {
        [userGet.pending.type]: (state) => {
            state.authStatus = AuthStatus.loading
        },
        [userGet.rejected.type]: (state, action: IApiError) => {
            if (action.error.message === '401') {
                state.authStatus = AuthStatus.unauthorized
            } else {
                state.authStatus = AuthStatus.error
            }
        },
        [userGet.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
            if (action.payload) {
                state.authStatus = AuthStatus.authorised
                state.data = action.payload
            } else {
                state.authStatus = AuthStatus.unauthorized
                state.data = null
            }
        },

        [requestPassword.pending.type]: (state) => {
            state.authStatus = AuthStatus.codeRequesting
            state.codeMessage = null
            state.emailMessage = null
        },
        [requestPassword.rejected.type]: (state, action: IApiError) => {
            if (action.error.message === '404') {
                state.emailMessage = {text: 'пользователь не найден', style: danger}
            }
            state.authStatus = AuthStatus.unauthorized
        },
        [requestPassword.fulfilled.type]: (state, action: PayloadAction<{seconds: number}>) => {
            state.authStatus = AuthStatus.codeRequested
            state.canPasswordRequestInSeconds = action.payload.seconds
            state.emailMessage = {text: 'проверьте Ваш email', style: success}
        },

        [userLogin.pending.type]: (state) => {
            state.authStatus = AuthStatus.authorising
            state.codeMessage = null
            state.emailMessage = null
        },
        [userLogin.rejected.type]: (state, action: IApiError) => {
            state.authStatus = AuthStatus.codeRequested
            switch (action.error.message) {
                case WRONG_CODE:
                    state.codeMessage = {text: 'неверный код авторизации', style: danger}
                    break
                case WRONG_EMAIL:
                    state.emailMessage = {text: 'неверный email', style: danger}
                    break
                case '401':
                    state.codeMessage = {text: 'неверный email или код', style: danger}
                    break
                case '403':
                    state.authStatus = AuthStatus.unauthorized
                    state.codeMessage = {text: 'код устарел', style: danger}
                    break
            }
        },
        [userLogin.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
            state.authStatus = AuthStatus.authorised
            state.data = action.payload
        },

        [usersAll.pending.type]: (state) => {
            state.loading = true
        },
        [usersAll.rejected.type]: (state, action: IApiError) => {
            state.loading = false
        },
        [usersAll.fulfilled.type]: (state, action: PayloadAction<IUser[]>) => {
            state.loading = false
            state.otherUsers = arrayPushOrRemoveIfExists(action.payload, state.data)
        },


        [userCreate.pending.type]: (state) => {
            state.loading = true
        },
        [userCreate.rejected.type]: (state, action: IApiError) => {
            state.loading = false
            if (action.error.message === WRONG_EMAIL) state.userCreateError = 'некорректный e-mail'
            else if (action.error.message === '400') state.userCreateError = 'пользователь уже существует'
            else state.userCreateError = 'непредвиденная ошибка'
        },
        [userCreate.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
            state.userCreateError = ''
            state.loading = false
            state.otherUsers.push(action.payload)
        }
    }
})



