import {Btn} from "../lib/buttons/Btn";
import {useAppDispatch, useAppSelector} from "../store/store";
import {bgPrimary, bold} from "../lib/styles";
import {userSlice} from "../store/user";

interface INavigation {
    title: string
    name: string
    visible: boolean
    online: 'online' | 'offline' | null
}


export default function (props: INavigation) {
    const dispatch = useAppDispatch()
    const current = useAppSelector(state => state.user.currentPage)
    if (!props.visible) return null
    function goTo() {
        dispatch(userSlice.actions.navigate(props.name))
    }
    const style = current === props.name ? {color: 'black', ...bold}: {color: 'black'}

    if (props.online) {
        if (props.online === 'online') style.color = 'green'
        else style.color = 'red'
    }
    return <Btn
        title={props.title}
        onPress={goTo}
        style={style}
    />
}
