import {useEffect, useState} from "react";
import {BtnSecondary} from "./Btn";


export interface IBtnTimer {
    style?: object
    visible?: boolean
    onPress?: any
    fontColor?: string
    width?: 'auto' | 'flex' | 'field'
    startSeconds: number
    endMessage: string
}

export default function (props: IBtnTimer): JSX.Element | null {
    if (!props.visible) return null
    const [counter, setCounter] = useState(props.startSeconds)

    useEffect(() => {
        if (!counter) return
        let timer = setTimeout(() => setCounter(counter - 1), 1000)
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [counter])

    if (counter) return <BtnSecondary
        title={String(counter)}
        style={props.style}
        width={props.width}
    />
    return <BtnSecondary
        title={props.endMessage}
        onPress={() => {
            props.onPress()
            setCounter(props.startSeconds)
        }}
        style={props.style}
        width={props.width}
    />
}
