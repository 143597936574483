import {Col, Flex1, Row} from "../../lib/markup/markup";
import {useAppDispatch, useAppSelector} from "../../store/store";
import Navigation from "../Navigation";
import {pages} from "../pages";
import {Btn} from "../../lib/buttons/Btn";


export default function (): JSX.Element | null {
    const user = useAppSelector(state => state.user.data)
    const amoCRM = useAppSelector(state => state.AmoCrm.data)
    const dispatch = useAppDispatch()
    if (!user) return null

    function checkOnline(array: object[]): 'online'| 'offline' {
        for (let obj of array) {
            // @ts-ignore
            if (!obj?.online) return 'offline'
        }
        return 'online'
    }
    function everyThingOnline(name: string) {
        switch (name.toLowerCase()) {
            case 'amocrm': return checkOnline(amoCRM)
            case 'google': return checkOnline(amoCRM)
            case 'yandex': return checkOnline(amoCRM)
            default: return null
        }
    }

    function logout() {
        dispatch({type: 'logout'})
    }

    return (
        <Row style={{backgroundColor: 'silver'}}>
            {pages.map((props, key) => {
                const visible = user?.pages.filter(({name}) => props.name === name).length !== 0
                return <Navigation key={key} {...props} visible={visible} online={everyThingOnline(props.name)}/>
            })}
            <Flex1/>
            <Btn title={'выйти'} onPress={logout}/>
        </Row>
    )
}
