import {useContext} from "react";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {DashboardContext} from "../../../contexts";
import {calculateWeekSummary, Modes, Periods} from "../../common";
import {useAppSelector} from "../../../../store/store";
import {Col, Flex1} from "../../../../lib/markup/markup";
import {H3} from "../../../../lib/static/text";
import {colors} from "../MonthChart";


export default function () {
    const {year, month, pipeline, mode, period} = useContext(DashboardContext)
    if (mode !== Modes.chart && period !== Periods.month) return null
    const {plans, leads} = useAppSelector(state => state.dashboard)
    const summary = calculateWeekSummary(year, month, leads, pipeline, plans)
    const data: object[] = []
    const weekNumbers = ['I', 'II', 'III', 'IV', 'V']
    const factYearBefore = `${year-1} факт`
    const planThisYear = `${year} план`
    const factThisYear = `${year} факт`
    const yearBefore = factYearBefore + ' успешно реализовано'
    const factThis = planThisYear + ' успешно реализовано'
    const planThis = factThisYear + ' успешно реализовано'
    for (let n=0; n<summary.length; n++) {
        const args = {
            name: 'неделя ' + weekNumbers[n],
            [yearBefore]: summary[n].factYearBefore.leadsSuccessCount,
            // @ts-ignore
            [factThis]: summary[n].planThisYear.leadsSuccessCount,
            // @ts-ignore
            [planThis]: summary[n].factThisYear.leadsSuccessCount
        }
        data.push(args)
    }
    return <Flex1>
             <Col>
                <H3>{`Сводный по периодам: успешно реализовано`}</H3>
                <LineChart
                width={window.innerWidth - 30}
                height={400}
                data={data}
                margin={{top: 15, right: 20, left: 10, bottom: 5}}
            >
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <CartesianGrid stroke="#f5f5f5"/>
                <Legend />
                    <Line type="monotone" dataKey={yearBefore} stroke={colors[2]}/>
                <Line type="monotone" dataKey={planThis} stroke={colors[3]}/>
                <Line type="monotone" dataKey={factThis} stroke={colors[0]}/>
            </LineChart>
            </Col>
    </Flex1>
}
