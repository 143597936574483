import {Col, Flex1, Row} from "../../lib/markup/markup";
import BtnToggle from "../../lib/buttons/BtnToggle";
import {useState} from "react";
import {m2, stickLeft, stickRight} from "../../lib/styles";
import FirstTouchReport from "./FirstTouch";


export default function () {
    enum Modes {
        FirstTouchReport,
    }
    const [mode, setMode] = useState(Modes.FirstTouchReport)
    const Component = FirstTouchReport
    return <Flex1>
        <Row style={m2}>
            <BtnToggle
                title={'первое касание'}
                onPress={() => setMode(Modes.FirstTouchReport)}
                value={mode === Modes.FirstTouchReport}
            />
        </Row>
        <Col>
            <Component/>
        </Col>
    </Flex1>
}
