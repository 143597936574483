import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    googleMainLeadDayCounterURL,
    IGoogleMainLeadDayCounter, IGoogleMainLeadDayCounterPath,
    ILead,
    IMainLeadState,
    initialMainLeadState
} from "./interfaces";
import {GET, IApiError, PATCH} from "../axios";
import {ELoadingStatus} from "../common";
import {leadsURL} from "../dashboard/consts";



export const loadData = createAsyncThunk(
    'mainLead/loadData',
    async (date: string) => {
        const newState: IMainLeadState = {...initialMainLeadState}
        newState.leads = (await GET<ILead[]>(leadsURL + `main_lead_stat/`, {date})).data
        newState.counter = (
            await GET<IGoogleMainLeadDayCounter>(googleMainLeadDayCounterURL + 'for_day/', {date})
        ).data
        return newState
    }
)


export const mainLeadSlice = createSlice({
    name: 'mainLead',
    initialState: initialMainLeadState,
    reducers: {
        path: (state, action: PayloadAction<IGoogleMainLeadDayCounterPath>) => {
            const {id, day_limit} = action.payload
            // @ts-ignore
            state.counter.day_limit= day_limit
            PATCH(googleMainLeadDayCounterURL + id + '/', {day_limit})
        },
    },
    extraReducers: {
        [loadData.pending.type]: (state) => {
            state.loading = ELoadingStatus.loading
        },
        [loadData.rejected.type]: (state, action: IApiError) => {
            state.loading = ELoadingStatus.error
        },
        [loadData.fulfilled.type]: (state, action: PayloadAction<IMainLeadState>) => {
            Object.assign(state, action.payload)
            console.log(action.payload)
            state.loading = ELoadingStatus.ready
        }
    }
})
