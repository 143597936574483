export function getObjectIndexById(array: any[], id: number | string): number {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) return i
    }
    return -1
}

export function removeFromArrayById(array: any[], id: number) {
    const index = getObjectIndexById(array, id)
    array.splice(index, 1)
    return array
}

export function arrayPushOrRemoveIfExists(array: any[], obj: any): any[] {
    if (obj['id'] === undefined) return array
    const index = getObjectIndexById(array, obj?.id)
    if (index === -1) array.push(obj)
    else array.splice(index, 1)
    return array
}

export function isEmailCorrect(email: string) {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}


export function zfill(str: string | number, length = 2) {
    let new_str = String(str)
    while (new_str.length < length) new_str = '0' + new_str
    return new_str
}


export class EDate extends Date {
    private readonly _weeks: EDate[][]
    constructor(...options: (string | number | Date)[]) {
        if (typeof options[0] === 'string' && options[0].length < 9) super('2000-01-01T' + options[0])
        else { // @ts-ignore
            super(...options);
        }
        this._weeks = []
    }

    copy() {
        return new EDate(
            this.getFullYear(),
            this.getMonth(),
            this.getDate(),
            this.getHours(),
            this.getMinutes(),
            this.getSeconds(),
            this.getMilliseconds()
        )
    }

    isoDate() {
        let month = String(this.getMonth() + 1)
        let day = String(this.getDate())
        return this.getFullYear() + '-' + zfill(month) + '-' + zfill(day)
    }

    isoTime(seconds = true) {
        if (seconds) return zfill(this.getHours()) + ':' + zfill(this.getMinutes()) + ':' + zfill(this.getSeconds())
        return zfill(this.getHours()) + ':' + zfill(this.getMinutes())
    }

    isoDatetime() {
        return this.isoDate() + ' ' + this.isoTime()
    }

    ruDate(full = true) {
        let month = String(this.getMonth() + 1)
        let day = String(this.getDate())
        if (full) return zfill(day) + '.' + zfill(month) + '.' + this.getFullYear()
        return zfill(day) + '.' + zfill(month)
    }

    ruDateTime(full = true) {
        return this.ruDate(full) + ' ' + this.isoTime(full)
    }

    weekDay(short = false) {
        if (short) {
            switch (this.getDay()) {
                case 1:
                    return 'ПН'
                case 2:
                    return 'ВТ'
                case 3:
                    return 'СР'
                case 4:
                    return 'ЧТ'
                case 5:
                    return 'ПТ'
                case 6:
                    return 'СБ'
                case 0:
                    return 'ВС'
            }
        }
        switch (this.getDay()) {
            case 1:
                return 'понедельник'
            case 2:
                return 'вторник'
            case 3:
                return 'среда'
            case 4:
                return 'четверг'
            case 5:
                return 'пятница'
            case 6:
                return 'суббота'
            case 0:
                return 'воскресенье'
        }
    }

    static weekdayNames(short: boolean = false) {
        if (short) return ['ПН','ВТ','СР','ЧТ','ПТ','СБ','ВС']
        return ['понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота', 'воскресенье']
    }

    change({days = 0, hours = 0, minutes = 0, seconds = 0}) {
        let delta = days * 86400000 || 0
        delta += hours * 3600000 || 0
        delta += minutes * 60000 || 0
        delta += seconds * 1000 || 0
        this.setTime(this.getTime() + delta)
        return this
    }

    monthName() {
        switch (this.getMonth()) {
            case 0:
                return 'январь'
            case 1:
                return 'февраль'
            case 2:
                return 'март'
            case 3:
                return 'апрель'
            case 4:
                return 'май'
            case 5:
                return 'июнь'
            case 6:
                return 'июль'
            case 7:
                return 'август'
            case 8:
                return 'сентябрь'
            case 9:
                return 'октябрь'
            case 10:
                return 'ноябрь'
            case 11:
                return 'декабрь'
        }
    }

    get weeksOnMonth(): EDate[][] {
        if (!this._weeks.length) {
            const date = new EDate()
            date.setDate(1)
            date.setMonth(this.getMonth())
            date.setFullYear(this.getFullYear())
            while (date.getMonth() === this.getMonth()) {
                if (!this._weeks.length || date.getDay() === 1) {
                    this._weeks.push([])
                }
                // @ts-ignore
                this._weeks[this._weeks.length - 1].push(date.copy())
                date.change({days: 1})
            }
        }
        return this._weeks
    }

    get weekOnMonthIndex(): number {
        for (let i=0; i<this.weeksOnMonth.length; i++) {
            for (const day of this.weeksOnMonth[i]) {
                if (this.isoDate() === day.isoDate()) return i
            }
        }
        return -1
    }
}

export function last(obj: any) {
    return obj[obj.length - 1]
}

export function round(num: number, decimal: number) {
    decimal = Math.pow(10, decimal)
    return Math.round((num + Number.EPSILON) * decimal) / decimal
}

export function daysInMonth(date: EDate | string) {
    const d = new EDate(date)
    d.setDate(33)
    return d.getDate()
}

export function getObjectById(array: any[], findId: number) {
    for (const item of array) {
        if (item.id === findId) return item
    }
}

export function numberWithSpaces(x: string|number|null|undefined, decimal: number=2):string {
    if (!x) return '0'
    x = round(+x, decimal)
    let parts = String(x).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}
