import {useAppDispatch, useAppSelector} from "../../../store/store";
import {useEffect, useState} from "react";
import {googleAnalyticsCreate, googleAnalyticsList, GoogleStatus} from "../../../store/google";
import Loading from "../../../lib/static/Loading";
import {fStart, m2, ml1, mt2} from "../../../lib/styles";
import {Flex1, Row} from "../../../lib/markup/markup";
import Creator from "../../../lib/fields/Creator";
import GoogleAnalyticsRow from "./GoogleAnalyticsRow";

export default function (): JSX.Element | null {
    const dispatch = useAppDispatch()
    const {data, status} = useAppSelector(state => state.googleAnalytics)

    useEffect(() => {
        if (status === GoogleStatus.initial) {
            dispatch(googleAnalyticsList())
        }
    }, []);

    if ([GoogleStatus.loading, GoogleStatus.initial].includes(status)) return <Loading/>
    return <Flex1 style={{...fStart}}>
        <Row>
            {data.map((google, key) => {
                return <GoogleAnalyticsRow key={key} {...google}/>
            })}
        </Row>
    </Flex1>
}

