import {Months} from "../common";
import {EDate} from "../../../lib/sugar";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {useContext} from "react";
import {DashboardContext} from "../../contexts";
import {useAppSelector} from "../../../store/store";
import {Col} from "../../../lib/markup/markup";
import {H3} from "../../../lib/static/text";
import {mt2} from "../../../lib/styles";
import Chart from "./Chart";

export default function () {
    const {year, month, pipeline} = useContext(DashboardContext)
    const {leads, plans} = useAppSelector(state => state.dashboard)
    const yearBeforeValue = year - 1
    const yearBefore = `${yearBeforeValue} факт`
    const planPositive = `${year} позитивный`
    const planNegative = `${year} негативный`
    const fact = `${year} факт`
    const monthData = {[yearBefore]: 0, [planNegative]: 0, [planPositive]: 0, [fact]: 0}
    const data = []

    for (const month of Months) {
        data.push({name: month.label, ...monthData})
    }
    for (let lead of leads) {
        const date = new EDate(lead.created_at)
        const leadYear = date.getFullYear()
        const m = date.getMonth()
        if (pipeline && pipeline.id !== lead.pipeline) continue
        if (leadYear === yearBeforeValue) {
            if (!!lead.closed_at) {
                // @ts-ignore
                data[m][yearBefore]++
            }
        } else if (leadYear === year) {
            // @ts-ignore
            if (!!lead.closed_at) data[m][fact]++
        }
    }
    for (let plan of plans) {
        if (plan.year !== year) continue
        if (pipeline && pipeline.id !== plan.pipeline) continue
            // @ts-ignore
            data[plan.month][planPositive] += plan.success_leads_positive
            // @ts-ignore
            data[plan.month][planNegative] += plan.success_leads_negative

    }
    const title = `Продажи: динамика ${year-1}-${year} ${pipeline ? "(" + pipeline.name + ")": ''}`
    return <Chart
        title={title}
        yearBefore={yearBefore}
        fact={fact}
        planNegative={planNegative}
        planPositive={planPositive}
        data={data}
    />
}
