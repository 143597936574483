
import {Modes, Periods} from "../common";
import {DashboardContext} from "../../contexts";
import {useContext} from "react";
import {Col, Row} from "../../../lib/markup/markup";
import Sales from "./Sales";
import Income from "./Income";
import Leads from "./Leads";
import SummaryIncome from "./SummaryIncome";
import SummarySales from "./SummarySales";
import SummaryLeads from "./SummaryLeads";


export default function (): JSX.Element | null {
    const {mode, period} = useContext(DashboardContext)
    if (mode !== Modes.chart || period !== Periods.year) return null
    return <Col>
        <Sales/>
        <Income/>
        <Leads/>
        <SummarySales/>
        <SummaryIncome/>
        <SummaryLeads/>
    </Col>
}

