import {useAppDispatch, useAppSelector} from "../../store/store";
import {getObjectIndexById} from "../../lib/sugar";
import {useEffect} from "react";
import {IPageAccess, pageAccessList, pageAccessSlice} from "../../store/page_access";
import Loading from "../../lib/static/Loading";
import {Flex1, Row} from "../../lib/markup/markup";
import {bold, ml2, mt1, mt2, p2} from "../../lib/styles";
import {TextMain} from "../../lib/static/text";
import BtnToggle from "../../lib/buttons/BtnToggle";
import {IUser, userSlice} from "../../store/user";
import Toggle from "../../lib/Toggle";

interface IPageAccessRow extends IPageAccess {
    currentUser: IUser
}

export default function (props: IPageAccessRow): JSX.Element | null {
    const dispatch = useAppDispatch()
    const isActive = getObjectIndexById(props.currentUser.pages, props.id) !== -1
    function onOff() {
        dispatch(
            userSlice.actions.pageAccess({
                pageAccess: {id: props.id, name: props.name},
                value: !isActive,
                user_id: props.currentUser.id
            })
        )
    }

    return <Toggle
        label={props.name}
        style={mt2}
        onPress={onOff}
        value={isActive}
    />
}
