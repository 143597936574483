import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DELETE, GET, IApiError, PATCH, POST, token} from "./axios";
import {getObjectIndexById, removeFromArrayById} from "../lib/sugar";


export interface IYandexApp {
    id: number
    name: string
    api_key: string
    client_id: string
    online: boolean
    access: string[]
}

export interface IYandexAppPath {
    id: number
    name?: string
    key?: string
    online?: boolean
    client_id?: string
}

export enum YandexAppStatus {
    initial,
    loading,
    error,
    ready
}

export interface IYandexAppList {
    data: IYandexApp[]
    status: YandexAppStatus
    checking: boolean
    access: IYandexAppAccess[]
}

export const initialYandexApp: IYandexAppList = {
    data: [],
    status: YandexAppStatus.initial,
    checking: false,
    access: []
}

export interface IYandexAppAccess {
    id: string,
    service: string
}

export const YandexAppAccessURL = 'yandex/YandexAppAccess/'

export const yandexAppList = createAsyncThunk(
    'yandex/list',
    async () => {
        const app_response = await GET<IYandexApp[]>(YandexAppURL)
        const access_response = await GET<IYandexAppAccess[]>(YandexAppAccessURL)
        return {app: app_response.data, access: access_response.data}
    }
)

const YandexAppURL = 'yandex/YandexApp/'

export const yandexAppCreate = createAsyncThunk(
    'yandex/create',
    async (args: { name: string }) => {
        const response = await POST<IYandexApp>(YandexAppURL, args)
        return response.data
    }
)

export interface IYandexAppPatch {
    id: number
    name?: string
    code?: string
    refresh_token?: string
    access_token?: string
    client_id?: string
    client_secret?: string
    subdomain?: string
    redirect_url?: string
}

export const yandexAppCheck = createAsyncThunk(
    'yandex/check_connection',
    async (id: number) => {
        const response = await GET(YandexAppURL + id + '/check_connection/')
        return response.data
    }
)

export const yandexApp = createSlice({
    name: 'IYandexApp',
    initialState: initialYandexApp,
    reducers: {
        patch: (state, action: PayloadAction<IYandexAppPatch>) => {
            const id = action.payload.id
            PATCH(`${YandexAppURL}${id}/`, action.payload)
            const i = getObjectIndexById(state.data, action.payload.id)
            state.data[i] = {...state.data[i], ...action.payload}
        },
        remove: (state, action: PayloadAction<number>) => {
            DELETE(YandexAppURL + action.payload + '/')
            state.data = removeFromArrayById(state.data, action.payload)
        },
        changeAccess: (state, action: PayloadAction<{app_id: number, access_id: string}>) => {
            const {app_id, access_id} = action.payload
            const i = getObjectIndexById(state.data, app_id)
            const accessIndex = state.data[i].access.indexOf(access_id)
            const access = state.data[i].access.slice()
            if (accessIndex !== -1) access.splice(accessIndex, 1)
            else access.push(access_id)
            state.data[i].access = access
            PATCH(YandexAppURL + app_id + '/access/', {access_id, access: accessIndex === -1})
        }
    },
    extraReducers: {
        [yandexAppList.pending.type]: (state) => {
            state.status = YandexAppStatus.loading
        },
        [yandexAppList.rejected.type]: (state, action: IApiError) => {
            state.status = YandexAppStatus.error
        },
        [yandexAppList.fulfilled.type]: (
            state, action: PayloadAction<{ app: IYandexApp[], access: IYandexAppAccess[] }>) => {
            const {app, access} = action.payload
            state.data = app
            state.access = access
            state.status = YandexAppStatus.ready
        },

        [yandexAppCreate.pending.type]: (state) => {
            state.status = YandexAppStatus.loading
        },
        [yandexAppCreate.rejected.type]: (state, action: IApiError) => {
            state.status = YandexAppStatus.error
        },
        [yandexAppCreate.fulfilled.type]: (state, action: PayloadAction<IYandexApp>) => {
            state.data.push(action.payload)
            state.status = YandexAppStatus.ready
        },

        [yandexAppCheck.pending.type]: (state) => {
            state.checking = true
        },
        [yandexAppCheck.rejected.type]: (state, action: IApiError) => {
            state.checking = false
        },
        [yandexAppCheck.fulfilled.type]: (state, action: PayloadAction<IYandexApp>) => {
            state.checking = false
            const index = getObjectIndexById(state.data, action.payload.id)
            state.data[index].online = action.payload.online
        },
    }
})



