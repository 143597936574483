import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DELETE, GET, IApiError, PATCH, POST, token} from "./axios";
import {storage} from "./storage";
import {IUserPatch} from "./user";
import {getObjectIndexById, removeFromArrayById} from "../lib/sugar";
import {success} from "../lib/styles";


export interface IAmoCrm {
    id: number
    name: string
    code: string
    refresh_token: string
    access_token: string
    client_id: string
    client_secret: string
    subdomain: string
    redirect_url: string
    online: boolean
}

export enum AmoCrmStatus {
    initial,
    loading,
    error,
    ready
}

export interface IAlamoCrmList {
    data: IAmoCrm[]
    status: AmoCrmStatus
    checking: boolean
}

export const initialAmoCrm: IAlamoCrmList = {
    data: [],
    status: AmoCrmStatus.initial,
    checking: false,
}

export const amoCrmList = createAsyncThunk(
    'AmoCrm/list',
    async () => {
        const response = await GET<IAmoCrm[]>(AmoCrmsURL)
        return response.data
    }
)

const AmoCrmsURL = 'amocrm/AmoCrms/'

export const amoCrmCreate = createAsyncThunk(
    'AmoCrm/create',
    async (args: {name: string}) => {
        const response = await POST<IAmoCrm>(AmoCrmsURL, args)
        return response.data
    }
)

export const amoCrmCheck = createAsyncThunk(
    'AmoCrm/check_connection',
    async (id: number) => {
        const response = await GET<IAmoCrm>(AmoCrmsURL + id + '/check_connection/')
        const online = response.status === 200
        return {id, online}
    }
)


export interface IAmoCrmPatch {
    id: number
    name?: string
    code?: string
    refresh_token?: string
    access_token?: string
    client_id?: string
    client_secret?: string
    subdomain?: string
    redirect_url?: string
}

export const amoCrmSlice = createSlice({
    name: 'AmoCrm',
    initialState: initialAmoCrm,
    reducers: {
        patch: (state, action: PayloadAction<IAmoCrmPatch>) => {
            const id = action.payload.id
            // @ts-ignore
            delete action.payload.id
            for (let i = 0; i < state.data.length; i++) {
                if (state.data[i].id === id) {
                    const args = action.payload.code ? {...action.payload, code_used: false} : action.payload
                    PATCH(`${AmoCrmsURL}${id}/`, args)
                    state.data[i] = {...state.data[i], ...action.payload}
                    break
                }
            }
        },
        remove: (state, action: PayloadAction<number>) => {
            DELETE(AmoCrmsURL + action.payload + '/')
            state.data = removeFromArrayById(state.data, action.payload)
        }
    },
    extraReducers: {
        [amoCrmList.pending.type]: (state) => {
            state.status = AmoCrmStatus.loading
        },
        [amoCrmList.rejected.type]: (state, action: IApiError) => {
            state.status = AmoCrmStatus.error
        },
        [amoCrmList.fulfilled.type]: (state, action: PayloadAction<IAmoCrm[]>) => {
            state.data = action.payload
            state.status = AmoCrmStatus.ready
        },

        [amoCrmCreate.pending.type]: (state) => {
            state.status = AmoCrmStatus.loading
        },
        [amoCrmCreate.rejected.type]: (state, action: IApiError) => {
            state.status = AmoCrmStatus.error
        },
        [amoCrmCreate.fulfilled.type]: (state, action: PayloadAction<IAmoCrm>) => {
            state.data.push(action.payload)
            state.status = AmoCrmStatus.ready
        },

        [amoCrmCheck.pending.type]: (state) => {
            state.checking = true
        },
        [amoCrmCheck.rejected.type]: (state, action: IApiError) => {
            state.checking = false
            console.log('err:', action)
        },
        [amoCrmCheck.fulfilled.type]: (state, action: PayloadAction<{id: number, online: boolean}>) => {
            const {id, online} = action.payload
            state.checking = false
            const index = getObjectIndexById(state.data, id)
            state.data[index].online = online
        }
    }
})



