import {round} from "../../../lib/sugar";
import {borderTable, p1} from "../../../lib/styles";
import {
    colTitleBG,
    getAverageTicket,
    getCPL,
    getCPS,
    getCR,
    getDPP, IAdvDayBudget, IDayOfWeek,
    IWeek
} from "../common";
import {Number, Percent, Rub} from "../../../lib/static/text";
import {ILead} from "../../../store/dashboard/consts";
import {useContext} from "react";
import {AppContext} from "../../../contexts";
import {DashboardContext} from "../../contexts";


interface IWeekProps {
    week: IWeek
    background: object
    advDayBudget: IAdvDayBudget[]
}

export default function ({week, background, advDayBudget}: IWeekProps) {
    const {updateState} = useContext(DashboardContext)
    const rows: any[] = []
    for (let day of week.days) {
        rows.push(getDay(day, advDayBudget[day.dayNumber].full))
    }
    const cellStyle = {...p1, ...borderTable}
    function detail(title: any) {
        const date = title.split(' ')[0].split('.')[0]
        updateState({detailWindow: {visible: true, day:date}})
    }
    return <>
        <tr style={background} onClick={() => detail(rows[0][0])}>
            <td style={{...p1, ...borderTable, ...colTitleBG}} rowSpan={week.days.length}>
                {week.title}
            </td>
            {<Cells row={rows[0]} style={cellStyle}/>}
        </tr>
        {rows.splice(1).map((row, key) => {
            return <tr key={key} style={background} onClick={() => detail(row[0])}>
                <Cells row={row} style={cellStyle}/>
            </tr>
        })}
    </>
}

function Cells({row, style}: { row: string[], style: object }) {
    return <>
        {row.map((col, key) => {
            return <td key={key} style={key ? style : {...style, ...colTitleBG}}>{col}</td>
        })}
    </>
}


function getDay(day: IDayOfWeek, dayAdvertisementBudget: number):
    [string, JSX.Element, JSX.Element, JSX.Element, JSX.Element, JSX.Element,
        JSX.Element, JSX.Element, JSX.Element, JSX.Element] {
    return [
        day.title,
        <Number>{day.leadsCount}</Number>,
        getCPL(dayAdvertisementBudget, day.leadsCount),
        <Rub>{round(dayAdvertisementBudget, 2)}</Rub>,
        getCR(day.leadSuccessCount, day.leadsCount),
        <Number>{day.leadSuccessCount}</Number>,
        getCPS(dayAdvertisementBudget, day.leadSuccessCount),
        getAverageTicket(day.totalPrice, day.leadSuccessCount),
        <Rub>{day.totalPrice}</Rub>,
        getDPP(dayAdvertisementBudget, day.totalPrice)
    ]
}
