import {Months} from "../common";
import {EDate} from "../../../lib/sugar";
import {useContext} from "react";
import {DashboardContext} from "../../contexts";
import {useAppSelector} from "../../../store/store";
import Chart from "./Chart";

export default function () {
    const {year, pipeline} = useContext(DashboardContext)
    const {leads, plans} = useAppSelector(state => state.dashboard)
    const yearBeforeValue = year - 1
    const yearBefore = `${yearBeforeValue} факт`
    const planPositive = `${year} позитивный`
    const planNegative = `${year} негативный`
    const fact = `${year} факт`
    const monthData = {[yearBefore]: 0, [planNegative]: 0, [planPositive]: 0, [fact]: 0}
    const data = []

    for (const month of Months) {
        data.push({name: month.label, ...monthData})
    }
    for (let lead of leads) {
        const date = new EDate(lead.created_at)
        const leadYear = date.getFullYear()
        const m = date.getMonth()
        if (pipeline && pipeline.id !== lead.pipeline) continue
        if (leadYear === yearBeforeValue) {
            // @ts-ignore
            data[m][yearBefore]++
        } else if (leadYear === year) {
            // @ts-ignore
            data[m][fact]++
        }
    }
    for (let plan of plans) {
        if (plan.year !== year) continue
        if (pipeline && pipeline.id !== plan.pipeline) continue
            // @ts-ignore
            data[plan.month][planPositive] += plan.leads_count_positive
            // @ts-ignore
            data[plan.month][planNegative] += plan.leads_count_negative

    }
    for (let m=1; m<12; m++) {
        for (const key of [yearBefore, planNegative, planPositive, fact]) {
            // @ts-ignore
            data[m][key]+= data[m-1][key]
        }
    }
    const title = `Суммарная по периодам СДЕЛКИ: ${year-1}-${year} ${pipeline ? "(" + pipeline.name + ")": ''}`
    return <Chart
        title={title}
        yearBefore={yearBefore}
        fact={fact}
        planNegative={planNegative}
        planPositive={planPositive}
        data={data}
    />
}
