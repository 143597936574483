import {Flex1, Row} from "../../lib/markup/markup";
import {useAppDispatch, useAppSelector} from "../../store/store";
import Loading from "../../lib/static/Loading";
import {ELoadingStatus} from "../../store/common";
import {TextMain} from "../../lib/static/text";
import {useEffect} from "react";
import {loadData} from "../../store/observer/slice";
import SiteOfflineEvents from "./SiteOfflineEvents";
import NotificationEmails from "./NotificationEmails";
import WebSites from "./WebSites";


export default function (): JSX.Element | null {
    const dispatch = useAppDispatch()
    const {loading, siteOfflineEvents, webSites, notificationEmails} = useAppSelector(state => state.observer)

    useEffect(() => {
        if (loading === ELoadingStatus.initial) dispatch(loadData())
    }, []);

    if (loading === ELoadingStatus.ready) return <Flex1>
        <Row>
            <WebSites webSites={webSites}/>
            <NotificationEmails notificationEmails={notificationEmails}/>
            <SiteOfflineEvents siteOfflineEvents={siteOfflineEvents}/>
        </Row>
    </Flex1>
    if (loading === ELoadingStatus.error) return <TextMain>something get wrong</TextMain>
    return <Loading/>
}


