import {ELoadingStatus} from "../common";

export interface IWebSite {
    id: number
    url: string
}

export interface ISiteOfflineEvent {
    id: number
    website: string
    created_at: string
    closed_at: string
}


export interface INotificationEmail {
    id: number
    email: string
}

export interface IObserver {
    loading: ELoadingStatus
    webSites: IWebSite[]
    siteOfflineEvents: ISiteOfflineEvent[]
    notificationEmails: INotificationEmail[]
}

export const initialObserverState: IObserver = {
    loading: ELoadingStatus.initial,
    webSites: [],
    siteOfflineEvents: [],
    notificationEmails: []
}

export const webSiteURL = 'main/WebSites/'
export const siteOfflineEventURL = 'main/SiteOfflineEvents/'
export const notificationEmailURL = 'main/NotificationEmails/'


export interface IObserverObjectCreate {
    objects: 'webSites' | 'siteOfflineEvents' | 'notificationEmails'
    args: object
}

export interface IObserverObjectCreated {
    objects: 'webSites' | 'siteOfflineEvents' | 'notificationEmails'
    object: IWebSite | ISiteOfflineEvent | INotificationEmail
}
export interface IObserverObjectRemove {
    objects: 'webSites' | 'siteOfflineEvents' | 'notificationEmails'
    id: number
}
