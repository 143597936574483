import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DELETE, GET, IApiError, PATCH, POST} from "../axios";
import {ELoadingStatus} from "../common";
import {
    IDashboardState,
    IStatus,
    ILead,
    IPipeline,
    leadsURL,
    statusesURL,
    pipelinesURL,
    advertisementsURL,
    IAdvertisement,
    IPlanCreate,
    plansURL,
    IPlan,
    IAdvertisementPatch,
    IPlanPatch,
    IAdvertisementCreate, ILeadChange
} from "./consts";
import {getObjectIndexById} from "../../lib/sugar";


export const initialDashboardState: IDashboardState = {
    loading: ELoadingStatus.initial,
    statuses: [],
    pipelines: [],
    leads: [],
    advertisements: [],
    plans: [],
    creationError: ''
}

export const loadData = createAsyncThunk(
    'dashboard/loadData',
    async () => {
        const newState: IDashboardState = {...initialDashboardState}
        newState.leads = (await GET<ILead[]>(leadsURL)).data
        newState.statuses = (await GET<IStatus[]>(statusesURL)).data
        newState.pipelines = (await GET<IPipeline[]>(pipelinesURL)).data
        newState.advertisements = (await GET<IAdvertisement[]>(advertisementsURL)).data
        newState.plans = (await GET<IPlan[]>(plansURL)).data
        newState.loading = ELoadingStatus.ready
        return newState
    }
)

export const advertisementCreate = createAsyncThunk(
    'dashboard/advertisementCreate',
    async (args: IAdvertisementCreate) => {
        const response = await POST(advertisementsURL, {...args, monthly: true})
        return response.data
    }
)

export const planCreate = createAsyncThunk(
    'dashboard/planCreate',
    async (args: IPlanCreate) => {
        const response = await POST(plansURL, args)
        return response.data
    }
)

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialDashboardState,
    reducers: {
        advertisementRemove: (state, action: PayloadAction<number>) => {
            const id = action.payload
            const i = getObjectIndexById(state.advertisements, id)
            DELETE(advertisementsURL + id + '/')
            state.advertisements.splice(i, 1)
        },
        planRemove: (state, action: PayloadAction<string>) => {
            const id = action.payload
            const i = getObjectIndexById(state.plans, id)
            DELETE(plansURL + id + '/')
            state.plans.splice(i, 1)
        },
        advertisementPatch: (state, action: PayloadAction<IAdvertisementPatch>) => {
            const {id, ...args} = action.payload
            const i = getObjectIndexById(state.advertisements, id)
            PATCH(advertisementsURL + id + '/', args)
            state.advertisements[i] = {...state.advertisements[i], ...args}
        },
        planPatch: (state, action: PayloadAction<IPlanPatch>) => {
            const {id, ...args} = action.payload
            const i = getObjectIndexById(state.plans, id)
            PATCH(plansURL + id + '/', args)
            state.plans[i] = {...state.plans[i], ...args}
        },
        leadPatch: (state, action: PayloadAction<ILeadChange>) => {
            const {id, ...args} = action.payload
            const i = getObjectIndexById(state.leads, id)
            console.log(args)
            PATCH(leadsURL + id + '/', args)
            state.leads[i] = {...state.leads[i], ...args}
        },
    },
    extraReducers: {
        [loadData.pending.type]: (state) => {

        },
        [loadData.rejected.type]: (state, action: IApiError) => {
            state.loading = ELoadingStatus.error
        },
        [loadData.fulfilled.type]: (state, action: PayloadAction<IDashboardState>) => {
            Object.assign(state, action.payload)
        },

        [advertisementCreate.rejected.type]: (state, action: IApiError) => {
            state.loading = ELoadingStatus.error
        },
        [advertisementCreate.fulfilled.type]: (state, action: PayloadAction<IAdvertisement>) => {
            state.advertisements.push(action.payload)
        },

        [planCreate.rejected.type]: (state, action: IApiError) => {
            state.creationError = action.error.message === '400' ? 'уже существует' : 'ошибка сервера'
        },
        [planCreate.fulfilled.type]: (state, action: PayloadAction<IPlan>) => {
            state.plans.push(action.payload)
            state.creationError = ''
        }
    }
})

