import {borderTable, fixWidth, mt2, mv1, p2} from "../../../lib/styles";
import {
    calculateAdv, calculateFactTotal,
    calculatePlanTotal,
    calculateWeeks,
    IAdvDayBudget,
    IWeek,
    Modes,
    Periods,
    rowTitleBG
} from "../common";
import Week from "./Week";
import {useContext, useEffect, useState} from "react";
import {DashboardContext, IFact, IPlan} from "../../contexts";
import Total from "./Total";
import {Col, Row} from "../../../lib/markup/markup";
import StatusTable from "./status_table/StatusTable";
import MonthWeekTable from "./MonthWeekTable";
import {useAppSelector} from "../../../store/store";
import Summary from "./Summary";
import {TextMain} from "../../../lib/static/text";

interface IMonthTableState {
    ready: boolean
    weeks: IWeek[]
    plan: IPlan | null
    fact: IFact | null
    advDayBudget: IAdvDayBudget[]
    advTotal: number
}

export default function (): JSX.Element | null {
    const {mode, period, month, year, pipeline, daysOnMonth} = useContext(DashboardContext)
    const {leads, advertisements, plans} = useAppSelector(state => state.dashboard)
    const initialState: IMonthTableState = {
        ready: false,
        weeks: [],
        plan: null,
        fact: null,
        advDayBudget: [],
        advTotal: 0
    }
    const [state, setState] = useState(initialState)

    function updateState(args: object) {
        setState({...state, ...args})
    }

    useEffect(() => {
        const weeks = calculateWeeks({leads, month, year, pipeline, advertisements})
        const plan = calculatePlanTotal(plans, month, year, pipeline)
        const fact = calculateFactTotal(month, year, pipeline, leads)
        const {
            advDayBudget,
            advTotal
        } = calculateAdv(advertisements, year, month, pipeline, daysOnMonth)
        updateState({ready: true, weeks, plan, fact, advDayBudget, advTotal})
    }, [pipeline, month, year, leads])

    const titles = [
        'Неделя',
        'Дата',
        'Заявки',
        'CPL, \u20bd',
        'Расход, \u20bd',
        'CR в продажу, %',
        'Успешно реализовано',
        'CPS, \u20bd',
        'Средний чек, \u20bd',
        'Доход, \u20bd',
        'ДРР, %',
    ]

    const widths = [
        100,
        80,
        100,
        120,
        120,
        120,
        120,
        120,
        120,
        120,
        120,
        100
    ]
    if (mode !== Modes.table || period !== Periods.month) return null
    if (!state.ready) return <TextMain>расчёт...</TextMain>
    const {weeks, plan, fact, advDayBudget, advTotal} = state
    const titleStyle = {...rowTitleBG, ...borderTable, ...p2}
    // @ts-ignore
    return <Col>
        <StatusTable
            advTotal={advTotal}
            // @ts-ignore
            plan={plan}
            // @ts-ignore
            fact={fact}
        />
        <TextMain style={mv1}>клик по строке - покажет информацию по сделкам детально:</TextMain>
        <table>
            <thead>
            <tr style={titleStyle}>
                {
                    titles.map((col, key) => {
                        return <th key={key} style={fixWidth(widths[key])}>{col}</th>
                    })
                }
            </tr>
            </thead>
            <tbody>
            {
                weeks.map((week, key) => {
                    const background = key % 2 ? {backgroundColor: '#ddd'} : {}
                    return <Week
                        key={key}
                        week={week}
                        background={background}
                        advDayBudget={advDayBudget}
                    />
                })
            }

            <Total
                titleStyle={titleStyle}
                advTotal={advTotal}
                // @ts-ignore
                fact={fact}
            />
            </tbody>
        </table>
        <Row style={mt2}>
            <MonthWeekTable/>
        </Row>
        <Row style={mt2}>
            <Summary/>
        </Row>
    </Col>
}


