import {ELoadingStatus} from "../common";
import {EDate} from "../../lib/sugar";

export interface IPipeline {
    id: number
    name: string
}

export interface IStatus extends IPipeline {
    amocrm_id: number
    pipeline: number
}

export interface ILead {
    id: string
    name: string
    created_at: string

    closed_at: string
    price: number
    price_manual_input: number
    pipeline: number
    status: number
}

export interface ILeadChange {
    id: string
    price_manual_input: number
}

export interface IAdvertisementCreate {
    date: string
    name: string
    cost: number
    vat: number
    pipeline: number
}

export interface IAdvertisement extends IAdvertisementCreate {
    id: number
    monthly: boolean
}

export interface IAdvertisementPatch {
    id: number
    date?: string
    name?: string
    cost?: number
    vat?: number
    pipeline?: number
}

export interface IDashboardState {
    loading: ELoadingStatus
    pipelines: IPipeline[]
    statuses: IStatus[]
    leads: ILead[]
    advertisements: IAdvertisement[]
    plans: IPlan[]
    creationError: string
}

export interface IPlanCreate {
    month: number
    year: number
    leads_count_positive: number
    leads_count_negative: number
    income_positive: number
    income_negative: number
    success_leads_positive: number
    success_leads_negative: number
    advertisement_budget: number
    pipeline: number
}

export interface IPlan extends IPlanCreate {
    id: string
}

export interface IPlanPatch {
    id: string
    leads_count_positive?: number
    leads_count_negative?: number
    income_positive?: number
    income_negative?: number
    success_leads_positive?: number
    success_leads_negative?: number
    advertisement_budget?: number
}

export const leadsURL = 'dashboard/LeadCaches/'
export const statusesURL = 'dashboard/statusCaches/'
export const pipelinesURL = 'dashboard/pipelineCaches/'
export const advertisementsURL = 'dashboard/advertisements/'
export const plansURL = 'dashboard/plans/'
