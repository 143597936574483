import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DELETE, GET, PATCH, POST} from "./axios";
import {getObjectIndexById} from "../lib/sugar";


interface IAppState {

}

export const initialAppState: IAppState = {

}


export const appSlice = createSlice({
    name: 'app',
    initialState: initialAppState,
    reducers: {

    },
    extraReducers: {

    }
})















