import {bgPrimary, fCenter, jCenter, ml1, primary, secondary} from "./styles";
import FA from "./static/FA";
import {faToggleOff} from "@fortawesome/free-solid-svg-icons/faToggleOff";
import {Row} from "./markup/markup";
import {faToggleOn} from "@fortawesome/free-solid-svg-icons/faToggleOn";
import {TextMain} from "./static/text";

interface IToggle {
    visible?: boolean
    label?: string | null | number
    style?: object
    onPress?: (b: boolean) => void
    value?: boolean
}

export default function ({
                             visible = true,
                             label = '',
                             style = {},
                             onPress,
                             value = false
                         }: IToggle): JSX.Element | null {
    if (!visible) return null
    return (
        <Row onPress={() => onPress ? onPress(!value) : null} style={{...fCenter, ...style}}>
            <FA icon={faToggleOff} visible={!value} size={"1x"} color={""}/>
            <FA icon={faToggleOn} visible={value} size={"1x"} color={""}/>
            <TextMain style={ml1}>{label}</TextMain>
        </Row>
    );
}
