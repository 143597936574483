import Table, {IColumn, IRow} from "../../../lib/table/Table";
import {bold} from "../../../lib/styles";
import {calculateDaysOfWeeks, getAverageTicket, getCPL, getCPS, getCR, getDPP, rowTitleBG} from "../common";
import {useContext} from "react";
import {DashboardContext} from "../../contexts";
import {EDate} from "../../../lib/sugar";
import {useAppSelector} from "../../../store/store";

export default function () {
    const {leads, advertisements} = useAppSelector(state => state.dashboard)
    const {pipeline, year, month} = useContext(DashboardContext)
    const weekdaysStat = calculateDaysOfWeeks(year, month, leads, pipeline, advertisements)
    const columns: IColumn[] = [
        {label: 'День недели', type: 'text'},
        {label: 'Звонки/заявки', type: 'number'},
        {label: 'CPL с НДС, ₽', type: 'text'},
        {label: 'Расход, ₽', type: 'rub'},
        {label: 'CR в продажу, %', type: 'text'},
        {label: 'Успешно реализовано', type: 'number'},
        {label: 'CPS (с НДС), ₽', type: 'text'},
        {label: 'Средний чек, ₽', type: 'text'},
        {label: 'Доход, ₽', type: 'text'},
        {label: 'ДРР, %', type: 'text'},
    ]
    const data: IRow[] = []
    const weekdayNames = EDate.weekdayNames()
    let leadsCountTotal = 0
    let leadsSuccessTotal = 0
    let incomeTotal = 0
    let advBudgetTotal = 0
    for (let i = 0; i < weekdaysStat.length; i++) {
        data.push({
            cells: [
                weekdayNames[i],
                weekdaysStat[i].leadsCount,
                getCPL(weekdaysStat[i].advertisingBudget, weekdaysStat[i].leadsCount),
                weekdaysStat[i].advertisingBudget,
                getCR(weekdaysStat[i].leadSuccessCount, weekdaysStat[i].leadsCount),
                weekdaysStat[i].leadSuccessCount,
                getCPS(weekdaysStat[i].advertisingBudget, weekdaysStat[i].leadSuccessCount),
                getAverageTicket(weekdaysStat[i].income, weekdaysStat[i].leadSuccessCount),
                weekdaysStat[i].income,
                getDPP(weekdaysStat[i].advertisingBudget, weekdaysStat[i].income)
            ]
        })
        leadsCountTotal += weekdaysStat[i].leadsCount
        leadsSuccessTotal += weekdaysStat[i].leadSuccessCount
        incomeTotal += weekdaysStat[i].income
        advBudgetTotal += weekdaysStat[i].advertisingBudget
    }
    data.push({
        cells: [
            'ИТОГО:',
            leadsCountTotal,
            getCPL(advBudgetTotal, leadsCountTotal),
            advBudgetTotal,
            getCR(leadsSuccessTotal, leadsCountTotal),
            leadsSuccessTotal,
            getCPS(advBudgetTotal, leadsSuccessTotal),
            getAverageTicket(incomeTotal, leadsSuccessTotal),
            incomeTotal,
            getDPP(advBudgetTotal, incomeTotal)
        ],
        style: {...rowTitleBG, ...bold}
    })
    return <Table
        columns={columns}
        data={data}
        headerStyle={rowTitleBG}
    />
}
