import {Flex1, Row} from "../../lib/markup/markup";
import {border, danger, fixWidth, flex1, m1, mh1, mh2, mv1, p1, p2, pb2, primary} from "../../lib/styles";
import Creator from "../../lib/fields/Creator";
import {IWebSite} from "../../store/observer/consts";
import {H3, TextMain} from "../../lib/static/text";
import {Btn} from "../../lib/buttons/Btn";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch} from "../../store/store";
import confirmAlert from "../../lib/modals/confirmAlert";
import {observerObjectCreate, observerSlice} from "../../store/observer/slice";
import {useState} from "react";



export default function ({webSites}: {webSites: IWebSite[]}): JSX.Element | null {
    const dispatch = useAppDispatch()
    const [url, setUrl] = useState('')

    function remove(id: number) {
        confirmAlert({
            title: 'удалить url?',
            yes: () => dispatch(observerSlice.actions.remove({objects: 'webSites', id}))
        })
    }

    function create() {
        dispatch(observerObjectCreate({objects: "webSites", args: {url}}))
    }

    return <Flex1 style={{...p2, ...border, ...m1}}>
        <H3 style={pb2}>отслеживать состояние:</H3>
        <Creator
            style={mv1}
            placeholder={'url'}
            onChange={setUrl}
            value={url}
            onPress={create}
        />

        {webSites.map((obj, key) => {
            return <Row key={key} style={mv1}>
                <TextMain style={flex1}>{obj.url}</TextMain>
                <Btn
                    style={primary}
                    width={'auto'}
                    icon={faTrash}
                    onPress={() => remove(obj.id)}
                />
            </Row>
        })}
    </Flex1>
}


