import {useAppDispatch, useAppSelector} from "../../../store/store";
import {useEffect, useState} from "react";
import {yandexAppCreate, yandexAppList, YandexAppStatus} from "../../../store/yandex";
import Loading from "../../../lib/static/Loading";
import {fStart, m2, ml1, mt2} from "../../../lib/styles";
import {Flex1, Row} from "../../../lib/markup/markup";
import Creator from "../../../lib/fields/Creator";
import YandexAppRow from "./YandexAppRow";


export default function (): JSX.Element | null {
    const dispatch = useAppDispatch()
    const {data, status} = useAppSelector(state => state.yandexApp)

    useEffect(() => {
        if (status === YandexAppStatus.initial) {
            dispatch(yandexAppList())
        }
    }, []);


    if ([YandexAppStatus.loading, YandexAppStatus.initial].includes(status)) return <Loading/>
    return <Flex1 style={{...fStart}}>
        <Row>
            {data.map((yandexApp, key) => {
                return <YandexAppRow key={key} {...yandexApp}/>
            })}
        </Row>
    </Flex1>
}
