import {Col, Row} from "../lib/markup/markup";
import {danger, fEnd, flex1, fStart, jBetween, jEnd, m1, mb2, mh2, mt2, p2, success} from "../lib/styles";
import {ReactNode} from "react";
import {H3, TextMain} from "../lib/static/text";
import {Btn} from "../lib/buttons/Btn";
import Remove from "../lib/buttons/Remove";


export function IntegrationWrapper({children, online}: { children: ReactNode, online: boolean }) {
    const style = {
        ...fEnd,
        ...m1,
        ...p2,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: online ? 'green' : 'red',
        backgroundColor: 'silver'
    }
    return <Col style={style}>{children}</Col>
}


export function IntegrationTitle({title, online, remove}: { title: string, online: boolean, remove: () => void }) {
    const color = online ? success : danger
    return <Row style={{...jBetween, ...mb2}}>
        <H3>{title}</H3>
        <TextMain style={{...flex1, ...color, ...mh2, ...jEnd}}>{online ? 'online' : 'offline'}</TextMain>
        <Remove
            onPress={remove}
            confirm={true}
        />
    </Row>
}
